<template>
    <div class="BackgroundColorPicker">
        <div class="background_colorpicker__ifield">
            <div @click="togglePicker()" class="background_colorpicker__sample"></div>
            <input
                type="text"
                name="playerbgcolor"
                v-model="colorValue"
                class="background_colorpicker__colorvalue "
                @focus="showPicker()"
            >
        </div>
        <div v-if="displayPicker" class="background_colorpicker__hszone">
            <div class="background_colorpicker__lightnessoverlay"></div>
            <div class="background_colorpicker__hszonepointer"></div>
        </div>
        <div v-if="displayPicker" class="background_colorpicker__valzone">
            <div class="background_colorpicker__valzonepointer" style="top: 50%;"></div>
        </div>
    </div>
</template>


<style lang="less">
    .BackgroundColorPicker {
        width: 80%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: stretch;
        align-content: space-between;
        margin-bottom: 15px;
    }

    .background_colorpicker__hszone {
        width: 87%;
        height: 14.8rem;
        background: linear-gradient(transparent, gray), linear-gradient(90deg, red, yellow, lime, cyan, blue, magenta, red);
        cursor: crosshair;
        position: relative;
        overflow: hidden;
    }

    .background_colorpicker__lightnessoverlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: transparent;
    }

    .background_colorpicker__hszonepointer, .background_colorpicker__valzonepointer {
        position: absolute;
        left: -0.9rem;
        top: -0.9rem;
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 50%;
        border: solid 2px white;
    }

    .background_colorpicker__valzone {
        width: 13%;
        height: auto;
        background: linear-gradient(to bottom, white, red, black);
        cursor: crosshair;
        position: relative;
        overflow: hidden;
    }

    .background_colorpicker__valzonepointer {
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
    }

    .background_colorpicker__ifield {
        display: flex;
        margin-bottom: 20px;
        border: 1px solid #81D3EA;
        .background_colorpicker__sample {
            width:50px;
            height: 30px;
            border-radius: 0.2rem;
            background-color: transparent;
        }
        .background_colorpicker__colorvalue {
            font-family: "Proxima Nova SemiBold", sans-serif;
            font-weight: normal;
            font-size: 1.5rem;
            line-height: 1.2;
            color: var(--additional-blue-color);
            text-decoration: none;
            width: 11rem;
            height: 100%;
            margin-left: 10px;
            outline: none;
            border: none;
            border-bottom: solid 1px var(--verylight-color);
        }
    }

    .background_colorpicker__colorvalue:focus {
        border-bottom-color: var(--additional-blue-color);
    }

    .videocustomize__colorpickersample {
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 0.2rem;
        background-color: transparent;

    }

    .videocustomize__colorpickerhexvalue {
        font-family: "Proxima Nova SemiBold", sans-serif;
        font-weight: normal;
        font-size: 1.5rem;
        line-height: 1.2;
        color: var(--additional-blue-color);
        text-decoration: none;
        width: 80%;
        margin-left: 1rem;
    }

    .videocustomize__colorpickervalueblock {
        margin-top: 1rem;
        border-bottom: solid 1px var(--verylight-color);
    }
</style>
<script>

    import BackgroundColorPicker from '../../assets/js/BackgroundColorPicker';

    export default {
        props: [
            'color',
            'control',
            'video'
        ],
        data() {
            return {
                colorValue: '',
                displayPicker: true
            }
        },
        components:{
            BackgroundColorPicker
        },
      created() {
          if(this.control == "global_video_color"){

              BackgroundColorPicker.run(this)
          }
          if(this.control === "video_funnel"){

              this.displayPicker = false
              this.colorValue = this.video.player_options.video_background_color
              this.setColorValue(this.video.player_options.video_background_color)

          }
      },

      methods: {
            setColorValue(value){
                this.colorValue = value
                this.video.player_options.video_background_color = value;
                console.log(this.colorValue, 'colorValue');
            },
            showPicker() {
                document.addEventListener('click', this.documentClick);
                this.displayPicker = true;
            },
            hidePicker() {
                // document.removeEventListener('click', this.documentClick);
                this.displayPicker = false;
            },
            togglePicker() {
                this.displayPicker ? this.hidePicker() : this.showPicker();
            },
        },
        watch: {
            colorValue(val) {
                if (val) {
                    this.$emit('input', val);

                    this.video.player_options.video_background_color = val;
                }
            },
            color(val){
                this.video.player_options.video_background_color = val;
                this.setColorValue(this.video.player_options.video_background_color)
                var element = document.querySelector('.background_colorpicker__sample');


                element.style.background = val;
                console.log(val, 'kjdskjsh============');
            },
            displayPicker(){
                BackgroundColorPicker.run(this)
            },
            control(val){
                // if(val == 'appearance' || val == 'video_funnel'){
                    BackgroundColorPicker.run(this)
                // }


            }
        },
    };
</script>
