<template>
    <el-aside v-bind:width="asideWidth + 'px'" class="level-aside" style="height: calc(100vh - 78px);">
        <slot></slot>
    </el-aside>
</template>
<style lang="less">
.level-aside {
    background-color: #FFFFFF !important;
    position: fixed;
    // overflow-y: scroll;
    display: flex;
    justify-content: flex-start;
    z-index: 99;
    bottom: 0;
    *, *:active, *:focus {
        outline: none;
    }
    hr {
        background: #fafafa;
        border-color: #fafafa;
        margin-left: -40px;
        margin-right: -40px;
    }
    .aside-level-1 {
        padding: 30px 40px;
        overflow-y: auto;
        .el-menu {
            border-right: none;
            background: none;
            .el-submenu__title,
            > .el-menu-item {
                border: 1px solid #F7FCFD;
                border-radius: 5px;
                -webkit-border-radius: 5px;
                padding-left: 14px !important;
		text-overflow: ellipsis;
		overflow: hidden;
		width: 100%;
                i {
                    &:first-of-type {
                        width: 20px;
                        display: inline-block;
                        margin-right: 21px !important;
                        height: 54px;
                        &::after {
                            height: 54px;
                            display: block;
                            width: 1px;
                            background: #F7FCFD;
                            border-right: 1px white;
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 40px;
                        }
                    }
                    &:last-of-type::after {
                        content: none;
                    }
                }
            }
            > .el-menu-item:hover,
            > .el-menu-item.is-active,
            .el-submenu__title:hover,
            .is-opened .el-submenu__title {
                background: #EBF9FA !important;
                border-color: #81D3EA !important;
            }
            .is-opened .el-submenu__title i:first-of-type::after {
                background: #81D3EA !important;
            }
            .el-submenu, > .el-menu-item {
                margin-bottom: 10px;
                background: none !important;
            }
            .el-menu-item {
                outline: none !important;
                background: none;
                cursor: pointer !important;
            }
            
            .pablishingDivider{
                color: var(--color-content);
                opacity: .7;
                padding: 10px 0 10px 14px;
                text-transform: uppercase;
                font-weight: normal;
                letter-spacing: 1px;
            }

        }
    }
    .aside-level-2 {
        border-left: 2px solid #fafafa;
        padding: 30px 0 30px 20px;
        position: relative;
        .el-form {
            .el-form-item__label {
                display: block;
                float: none;
                text-align: left;
            }
        }
        #level2actions {
            margin-top: auto;
            display: flex;
            justify-content: center;
            z-index: 899;
        }
    }
}
</style>
<script>
export default {
    name: 'ThreeLevelAside',
    props: ['asideWidth']
}
</script>
