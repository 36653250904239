<template>
  <div class="VideoSelector">
    <div class="video-selector-wrap">
      <draggable
        :list="video_list_selected"
        class="videos-wrap"
        v-model="video_list_selected"
        ghost-class="ghost"
        draggable=".video-item"
      >
        <div
          class="video-item"
          v-for="video in video_list_selected"
          :key="video.id"
          :title="video.title"
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="Remove video"
            placement="top"
          >
            <div
              class="video-remove-button"
              @click="removeVideo(video.id)"
            ></div>
          </el-tooltip>
          <div class="video-thumbnail">
            <div
              class="img"
              :style="{
                'background-image': 'url(' + croppa(video.thumbnail, 300) + ')',
              }"
            ></div>
            <span class="video-duration">{{ video.duration_formatted }}</span>
          </div>
        </div>
      </draggable>
      <div class="video-add" slot="footer" @click="dialogFormVisible = true">
        <div class="inner video-item">
          <div class="video-thumbnail">
            <img
              src="../../assets/img/add_video_icon.svg"
              alt=""
            />&nbsp;&nbsp;+ Add Media
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="Add Media"
      class="el-dialog--huge"
      :visible.sync="dialogFormVisible"
      :modalAppendToBody="false"
    >
      <div v-if="video_list_loaded">
        <div class="tools">
          <div class="filter__item" style="height: 25px">
            <el-dropdown placement="bottom">
              <el-button type="default" round>
                <svg
                  class="filter__icon"
                  width="16.913"
                  height="8.81"
                  viewBox="0 0 16.913 8.81"
                >
                  <g
                    id="Group_5905"
                    data-name="Group 5905"
                    transform="translate(-316.85 -248.85)"
                  >
                    <line
                      id="Line_353"
                      data-name="Line 353"
                      x2="15.613"
                      transform="translate(317.5 249.5)"
                      fill="none"
                      stroke="#778d9d"
                      stroke-linecap="round"
                      stroke-width="1.3"
                    />
                    <line
                      id="Line_353-2"
                      data-name="Line 353"
                      x2="12"
                      transform="translate(317.5 253.255)"
                      fill="none"
                      stroke="#778d9d"
                      stroke-linecap="round"
                      stroke-width="1.3"
                    />
                    <line
                      id="Line_353-3"
                      data-name="Line 353"
                      x2="8"
                      transform="translate(317.5 257.01)"
                      fill="none"
                      stroke="#778d9d"
                      stroke-linecap="round"
                      stroke-width="1.3"
                    />
                  </g>
                </svg>
                <span class="filter__name">By </span
                ><span class="sort-by__value">{{ videoSortBy }}</span>
                <svg
                  class="icon-arrow__down"
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.709"
                  height="5.395"
                  viewBox="0 0 9.709 5.395"
                >
                  <path
                    id="Path_2500"
                    data-name="Path 2500"
                    d="M15.582,33.5a.328.328,0,0,0-.463,0l-4.075,4.083L6.959,33.5a.328.328,0,1,0-.463.463L10.8,38.267a.32.32,0,0,0,.232.1.334.334,0,0,0,.232-.1l4.307-4.307A.321.321,0,0,0,15.582,33.5Z"
                    transform="translate(-6.168 -33.168)"
                    fill="#778d9d"
                    stroke="#778d9d"
                    stroke-width="0.4"
                  />
                </svg>
              </el-button>
              <el-dropdown-menu slot="dropdown" class="filter__dropdown__menu">
                <el-dropdown-item
                  v-for="item in sortOptions"
                  :key="'sort_by' + item.value"
                >
                  <div @click="videoSortBy = item.value">
                    {{ item.label }}
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div
            class="filter__item"
            style="height: 25px"
            @click="visibility.filter = !visibility.filter"
          >
            <multiple-select-popover
              :buttonName="'Filter'"
              :on-done="onDoneFilter"
              :on-cancel="onCancelFilter"
              :visible="visibility.filter"
              v-on:cancel-pushed="visibility.filter = false"
              v-on:done-pushed="visibility.filter = false"
            >
              <filter-icon slot="icon"></filter-icon>
              <filter-option-items
                slot="selectOptions"
                @changeFilter="onChangeFilter($event)"
              />
            </multiple-select-popover>
          </div>
          <div class="toolbar__search ml-auto">
            <div class="flex-center search__icon">
              <el-input
                class="filter__search"
                v-model="searchQuery"
                placeholder="Search Videos"
              >
                <template slot="prepend">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.6"
                    height="16.6"
                    viewBox="0 0 16.6 16.6"
                  >
                    <path
                      id="icon_search-2"
                      d="M12.7,11.23a6.777,6.777,0,0,0,1.4-4.174A7.02,7.02,0,0,0,7.1,0,7.105,7.105,0,0,0,0,7.056a7.105,7.105,0,0,0,7.1,7.056,6.667,6.667,0,0,0,4.2-1.391l3,2.981a.971.971,0,0,0,1.4,0,.957.957,0,0,0,0-1.391Zm-5.6.8A5.022,5.022,0,0,1,2,7.056a5.1,5.1,0,1,1,10.2,0,5.022,5.022,0,0,1-5.1,4.969Z"
                      transform="translate(0.3 0.3)"
                      fill="#00acdc"
                      stroke="#fff"
                      stroke-width="0.6"
                    />
                  </svg>
                </template>
              </el-input>
            </div>
          </div>
        </div>
        <div class="video-modal-list scroll">
           
          <div
            v-for="(video, i) in videosFiltered"
            :class="{ 'is-active': modal_video_list.includes(video.id) }"
            class="video-modal-list-item"
            click="videoToggle(video)"
            :key="i"
          >
            <div class="video-thumbnail">
              <!-- <el-checkbox v-model="checked">Option</el-checkbox> -->
              <el-checkbox
                :checked="modal_video_list.includes(video.id)"
                @change="selectVideo($event, video)"
              ></el-checkbox>
              <div
                class="img"
                :style="{ 'background-image': 'url(' + video.thumbnail + ')' }"
              ></div>
              <span class="video-duration">{{ video.duration_formatted }}</span>
            </div>
            <div class="video-title">{{ video.title }}</div>
            <div class="video-meta">{{ video.date_formatted }}</div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="text-center loading-space">
          <i class="el-icon-loading"></i>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="el-button--gray" @click="dialogFormVisible = false"
          >Cancel</el-button
        >
        <el-button
          type="el-button btn btn-primary el-button--submit"
          @click="select"
          >Apply</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less">
@import "../../assets/less/custom/fonts";

.VideoSelector {
  .el-dialog {
    height: 75vh;
    display: flex;
    flex-direction: column;
    .el-dialog__body {
      overflow: hidden;
      padding: 25px 10px 15px 40px;
      flex: 1;

      & > div {
        height: 100%;
        .tools {
          display: flex;
          align-items: center;
          padding-right: 30px;
        }
        .video-modal-list {
          max-height: 100%;
          overflow-x: hidden;
          padding-right: 30px;
          margin-top: 20px;
        }
      }
    }
    .el-dialog__footer {
      margin-top: 25px;
    }
    .el-dialog__header {
      display: flex;
      padding: 20px 20px;
      justify-content: center;
      .el-dialog__headerbtn {
        position: absolute;
        right: 20px;
      }
    }
  }
}

.videos-wrap {
  display: flex;
  flex-wrap: wrap;

  .video-item {
    width: 50%;
    max-width: 200px;
    display: inline-block;
    position: relative;
    cursor: pointer;

    &:hover {
      .video-thumbnail {
        &:before {
          opacity: 1;
        }
      }

      .video-remove-button {
        display: block !important;
      }
    }

    .video-thumbnail {
      position: relative;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      overflow: hidden;
      /*height: 90px;*/

      &:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
      }

      .img {
        width: 100%;
        padding-top: 56.25%;
        background-repeat: no-repeat;
        background-position: center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
      }

      .video-duration {
        background: #011a22;
        display: inline-block;
        position: absolute;
        bottom: 10px;
        right: 10px;
        border-radius: 3px;
        -webkit-border-radius: 3px;
        color: #fff;
        font-size: 13px;
        padding: 3px 8px;
      }

      &:before {
        transition: opacity 0.3s;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        content: " ";
        display: block;
        background: url("../../assets/img/drag_icon.svg") no-repeat center
          center;
        background-color: rgba(1, 26, 34, 0.4);
        opacity: 0;
      }
    }

    .video-remove-button {
      cursor: pointer;
      background: url("../../assets/img/remove_video_icon.svg") no-repeat;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 99;
      width: 23px;
      height: 23px;
      display: none;
    }
  }

  .video-add {
    width: 50%;
    max-width: 200px;
    display: inline-block;
    position: relative;
    padding: 15px;
    cursor: pointer;

    .inner {
      height: 90px;
      background: #f4fdff;
      color: #00acdc;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      font-size: 14px;
    }
  }
}

.filter__item {
  margin-right: 19px;

  .el-dropdown {
    .el-button {
      padding: 5px 12px 5px 15px !important;
      background-color: var(--color-grey-light);
      border: none;
      color: var(--color-grey);
      font-size: 13px;
      line-height: 17px;
      border-radius: 30px !important;

      .filter__icon {
        margin-right: 12px;
        vertical-align: middle;
      }

      .icon-arrow__down {
        margin-left: 10px;
        vertical-align: middle;
      }

      span {
        vertical-align: middle;
      }

      .sort-by__value {
        display: inline-block;
        min-width: 35px;
        text-transform: capitalize;
        text-align: left;
        vertical-align: middle;
      }
    }
  }

  .filter__more.el-button {
    display: flex;
    height: 29px;
    width: 29px;
    padding: 0 !important;
    background-color: var(--color-grey-light);
    color: var(--color-grey);
    border: none;

    svg {
      display: block;
      margin: auto;
    }
  }
}

.filter__dropdown__menu {
  list-style: none;
  width: 100px;
  position: relative;
  border: 1px solid var(--color-blue);
  border-radius: 5px;
  margin-top: 15px;
  padding: 15px 0 10px;
  font-family: "Helvetica Neue";

  .el-dropdown-menu__item {
    font-size: 14px;
    padding: 0 17px;

    &:hover {
      color: var(--color-blue);
    }
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    top: -8px;
    left: calc((100px - 16px) / 2);
    transform: rotate(45deg);
    border: 1px solid var(--color-blue);
    border-bottom-color: transparent;
    border-right-color: transparent;
    background-color: white;
    border-radius: 3px;
  }

  .divider {
    height: 1px;
    background-color: #ebf9fa;
    margin: 5px 0 10px;
  }

  .popper__arrow {
    display: none;
  }
}

.filter__search {
  display: flex;
  align-items: center;
  border: 1px solid #99d6e9;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;

  .el-input-group__prepend {
    display: block;
    width: auto;
    background-color: transparent;
    line-height: 0;
    border: 0;
    padding-right: 8px;
    padding-left: 8px;

    svg {
      width: 16px;
    }
  }

  input.el-input__inner {
    box-sizing: border-box;
    /*width: 0;*/
    width: 180px;
    height: 32px;
    padding-left: 0;
    padding-right: 0;
    font-size: 13px;
    border: 0;
    transition: all 0.3s ease-out;
  }

  /*&:focus-within, &:hover {*/
  /*border-color: #00acdc;*/

  /*.el-input-group__prepend {*/
  /*svg {*/
  /*path {*/
  /*fill: #00acdc;*/
  /*}*/
  /*}*/
  /*}*/

  /*input.el-input__inner {*/
  /*width: 180px;*/
  /*padding-right: 10px;*/
  /*}*/
  /*}*/
}

.video-modal-list {
  padding: 15px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .video-modal-list-item {
    width: 30%;
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
    padding: 10px 10px 15px;
    cursor: pointer;
    border: 2px inset transparent;

    &.is-active,
    &:hover {
      border: 2px inset #21455e;
      background: #f6f7f9;
    }

    .video-title {
      font-weight: 500;
      color: #21455e;
    }

    .video-meta {
      opacity: 0.7;
    }

    .video-thumbnail {
      margin-bottom: 10px;
      position: relative;

      // .el-checkbox {
      //     position: absolute;
      //     top: 10px;
      //     left: 10px;
      //     opacity: 0;

      //     .el-checkbox__input {
      //         &.is-checked .el-checkbox__inner {
      //             background-color: #21455E !important;
      //             border-color: #21455E !important;
      //         }

      //         &:not(.is-checked) .el-checkbox__inner {
      //             background-color: transparent;
      //         }
      //     }
      // }

      .img {
        width: 100%;
        max-width: 100%;
        padding-top: 56.25%;
        background-repeat: no-repeat;
        background-position: center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
      }

      .video-duration {
        background: #011a22;
        display: inline-block;
        position: absolute;
        bottom: 10px;
        right: 10px;
        border-radius: 3px;
        -webkit-border-radius: 3px;
        color: #fff;
        font-size: 16px;
        padding: 3px 8px;
      }
    }

    &:hover .el-checkbox,
    &.is-active .el-checkbox {
      opacity: 1;
    }
  }
}
</style>
<script>
import MultipleSelectPopover from "../Admin/UsersTab/MultipleSelectPopover";
import FilterOptionItems from "../Project/AllProjects/FilterOptionItems";
import FilterIcon from "../Admin/SvgComponents/FilterIcon";

export default {
  props: ["value"],
  data() {
    return {
      checked: true,
      sortOptions: [
        { value: "date", label: "Date" },
        { value: "title", label: "Title" },
        { value: "views", label: "Views" },
        // {value: 'clicks', label: 'Clicks'},
        // {value: 'leads', label: 'Leads'}
      ],
      searchQuery: "",
      videoSortBy: "date",
      filter: {},
      visibility: {
        filter: false,
      },
      dialogFormVisible: false,
      video_list: [],
      video_list_loaded: false,
      video_list_selected: [],
      modal_video_list: [], // list of video selected in modal
      filtered_videos: [],
    };
  },
  mounted() {
    this.loadVideoList();
  },
  methods: {
    onDoneFilter() {
      this.loadVideoList(true);
    },
    onCancelFilter() {
      this.filter = {};
    },
    onChangeFilter(data) {
      this.filter = data;
    },
    select(value) {
      var $this = this;
      this.dialogFormVisible = false;
      this.video_list_selected = this.video_list.filter((x) =>
        $this.modal_video_list.includes(x.id)
      );
    },
    selectVideo(e, video) {
      if (!e) {
        let index = this.modal_video_list.indexOf(video.id);

        if (index !== -1) {
          this.modal_video_list.splice(index, 1);
        }
      }else{
            if(!this.modal_video_list.includes(video.id)){
                this.modal_video_list.push(video.id)
            }
      }
    },
    loadVideoList(filter) {
      // get videos via axios for user in project
      this.axios({
        method: "get",
        url: "project/0/videos",
        params: { filter: this.filter },
      }).then((response) => {
        if (filter) {
          this.filtered_videos = response.data;
        } else {
          this.video_list = response.data;
          this.filtered_videos = response.data;
        }
        if (this.value) {
          // compose selected videos list from :value video ids
          let video_list = this.video_list;
          this.video_list_selected = this.value.map(function (item) {
            var id = item;
            if (item.id) id = item.id; // object instead of value
            return video_list.filter(function (video) {
              return video.id == id;
            })[0];
          });
          this.modal_video_list = this.value;
        }
        this.video_list_loaded = true;
      });
    },

    removeVideo(id) {
      this.video_list_selected = this.video_list_selected.filter(function (
        video
      ) {
        return video.id != id;
      });
      this.modal_video_list = this.video_list_selected.map(function (video) {
        return video.id;
      });
    },

    videoToggle(video) {
      var index = this.modal_video_list.indexOf(video.id);
      if (index > -1) this.modal_video_list.splice(index, 1);
      else this.modal_video_list.push(video.id);
    },
  },
  watch: {
    // update the value
    video_list_selected: function (video_list_selected) {
      try {
        if (this.video_list_loaded) {
          var video_id_list = video_list_selected.map((x) => x.id);
          this.$emit("input", video_id_list);
        }
      } catch (err) {}
    },
    dialogFormVisible: function (newValue, oldValue) {
      if (!newValue) {
        this.visibility.filter = false;
      }
    },
  },
  computed: {
    videosFiltered() {
      let filtered_videos = this.filtered_videos;
      if (this.searchQuery) {
        filtered_videos = this.video_list.filter((item) => {
          let searched = false;
          for (let key in item) {
            let v = item[key];
            if (
              typeof v === "string" &&
              v.toLowerCase().search(this.searchQuery.toLowerCase()) !== -1
            ) {
              searched = true;
              break;
            }
          }
          return searched;
        });
      }
      filtered_videos.sort((a, b) => {
        let av, bv;
        switch (this.videoSortBy) {
          case "date":
            av = "updated_at" in b ? b["updated_at"] : 0;
            bv = "updated_at" in a ? a["updated_at"] : 0;
            break;
          default:
            av = a[this.videoSortBy];
            bv = b[this.videoSortBy];
            break;
        }
        if (av > bv) {
          return 1;
        } else if (av < bv) {
          return -1;
        } else {
          return 0;
        }
      });
      return filtered_videos;
    },
  },
  components: {
    MultipleSelectPopover,
    FilterOptionItems,
    FilterIcon,
  },
};
</script>
