<template>
    <div class="EditorInteractionTools"> 
        <BasePlanRestriction :showModal='showRestrictionModal' @close='showRestrictionModal = false'/>
        <div v-show="activeLevel2 == 'interaction-before'">
            <p clas="tool_title">Before Video</p>

            <p class="tool_description">Capture emails before viewers can watch the video.</p>

            <!-- email capture before -->
            <div class="row-space-5">
                <label for="">Email Capture</label>
                <!-- :class='email_capture_restrict ? "blurred" : ""' -->
                <span v-if='!email_capture_restrict'>
                    <el-switch v-if="!video.player_options.text_overlay" v-model="video.player_options.interaction_before_email_capture"
                           class="row-space-right-1 custom_green" ></el-switch>
                    <el-switch v-else  @change="showPrompt()" v-model="video.player_options.interaction_before_email_capture"
                           class="row-space-right-1 custom_green" ></el-switch>
                </span>
                <span v-else style='position: relative'>
                    <popover v-if='showEmailRestrictPopover' message='Email capture is not included in your plan, please upgrade.' bottom='21px' width='230px'></popover>
                    <span @mouseenter="showEmailRestrictPopover = true" @mouseleave="showEmailRestrictPopover = false">
                        <el-switch></el-switch>
                    </span>
                </span>
                <div v-if="video.player_options.interaction_before_email_capture" class="row-space-top-3">
                    <div>
                        <label for="">Type</label>
                        <br>
                        <el-radio v-model="video.player_options.interaction_before_email_capture_type" label="full">
                            Full
                        </el-radio>
                        <br>
                        <el-radio v-model="video.player_options.interaction_before_email_capture_type"
                                  label="minimized">Minimized <span class="mini-text">(desktop)</span>
                        </el-radio>
                    </div>

                    <div class="row-space-top-3">
                        <label for="">Options</label>

                        <div class="">
                            <el-checkbox v-model="video.player_options.interaction_before_email_capture_firstname">
                                Firstname  &  Last Names <span class="mini-text">(desktop)</span>
                            </el-checkbox>
                        </div>

                        <div class="">
                            <el-checkbox v-model="true_value" disabled>Email</el-checkbox>
                        </div>
                        <div class="">
                            <el-checkbox v-model="video.player_options.interaction_before_email_capture_phone_number">
                                Phone number <span class="mini-text">(desktop)</span>
                            </el-checkbox>
                        </div>
                        <div class="">
                            <el-checkbox v-model="video.player_options.interaction_before_email_capture_allow_skip">
                                Allow skip
                            </el-checkbox>
                        </div>
                    </div>

                    <hr>

                    <div>
                        <label for="">Upper Text</label>

                        <el-input type="textarea" class="row-space-top-1"
                                  v-model="video.player_options.interaction_before_email_capture_upper_text"></el-input>
                    </div>


                    <hr>

                    <div>
                        <label for="">Lower Text</label>

                        <el-input type="textarea" class="row-space-top-1"
                                  v-model="video.player_options.interaction_before_email_capture_lower_text"></el-input>
                    </div>

                    <hr>

                    <div class="relative"
                         :class="{'el-form-item is-error':errors.has('before_email_capture_button_text')}">
                        <label for="">Button</label>
                        <el-input class="row-space-top-1"
                                  v-model="video.player_options.interaction_before_email_capture_button_text"
                                  v-validate="'required'" name="before_email_capture_button_text"></el-input>
                        <div class="el-form-item__error" v-if="errors.has('before_email_capture_button_text')">This
                            field is required.
                        </div>
                    </div>

                    <hr>

                    <div class="relative"
                         :class="{'el-form-item is-error':errors.has('player_options.interaction_before_email_capture_email_list')}">
                        <label for="">Email List</label>

                        <div class="row-space-top-1">
                            <el-select
                                    v-model="video.player_options.interaction_before_email_capture_email_list"
                                    name="interaction_before_email_capture_email_list"
                                    placeholder="Select"
                                    style="width:100%"
                                    popper-class="custom_select_pop with_arrow_190"
                                    >
                                <el-option label="Select" value="-1">Select</el-option>
                                <el-option-group v-for="group in email_providers" :key="group.mailer"
                                                 :label="getNicename(group.mailer)">
                                    <el-option v-for="item in group.lists" :key="item.value" :label="item.name"
                                               :value="group.mailer + '_' + item.id">
                                    </el-option>
                                </el-option-group>
                            </el-select>
                            <div class="el-form-item__error"
                                 v-if="errors.has('interaction_before_email_capture_email_list')">This field is
                                required.
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="">
                        <label for="">Tags
                            <span v-if="video.player_options.interaction_before_email_capture_email_tags">({{video.player_options.interaction_before_email_capture_email_tags.length}})</span>
                        </label>

                        <div class="row-space-top-1 interaction-tool">
                            <el-input name="interaction_before_tag_input" placeholder="Enter tag"
                                      v-model="interaction_before_tag_input" @keyup.enter.native="addTag('before')"
                                      required>
                                <el-button slot="append" @click="addTag('before')" class="el-icon-plus"></el-button>
                            </el-input>
                        </div>

                        <div class="row-space-top-2">
                            <el-tag v-for="tag in video.player_options.interaction_before_email_capture_email_tags"
                                    v-bind:key="tag" closable @close="removeTag('before', tag)" type="success">{{tag}}
                            </el-tag>
                        </div>
                    </div>

                </div>
            </div>
            <!-- /email capture before -->

        </div>

        <div v-show="activeLevel2 == 'interaction-during'">
            <BaseUpgradeModal :disableCancelButton='true' zIndex='4000' :showLimitAlert='showDuringRestrictionModal' messageBody='Please upgrade to unlock during video interaction options.'/>
            <div :class='showDuringRestrictionModal ? "blurred" : ""'>
            <p clas="tool_title" >During Video</p>

            <p class="tool_description">
                Add call-to-action, capture emails while viewers are watching the video.
            </p>

            <!-- call to action during -->
            <div class="row-space-4 clearfix">
                <p style="font-size: 13px">Call-to-Action</p>

                <div class="row-space-top-1">
                    <span style="display: flex; align-items: center">
                        <el-switch
                            v-model="video.player_options.interaction_during_active"
                            class="row-space-right-1"
                            style="margin-right: 20px"
                       >
                       </el-switch>
                        Enable Call-to-Action
                    </span>
                </div>

                <div v-if="video.player_options.interaction_during_active" class="row-space-top-4">
                    <div class="timeBox">
                        <el-input
                                class="time"
                                placeholder="00:00:00"
                                :value="during_time"
                                @input="changeInteractionDuringTime($event)"
                                style="width: 50%;"
                                pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                        >
                            <template slot="prepend">Time</template>
                        </el-input>
                    </div>

                    <div class="centerInputs">
                        <div class="row-sapce-top-3 inputBox">
                            <label for="">Type</label>
                            <el-select
                                    v-model="video.player_options.interaction_during_type"
                                    popper-class="custom_select_pop with_arrow_190"
                                    placeholder="Select"
                                    style="width: 100%;"
                            >
                                <el-option
                                        v-for="item in [{value: 'text', label: 'Text'}, {value: 'image', label:'Image'},{ value: 'html_code', label: 'HTML'}]"
                                        :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </div>

                        <!-- text -->
                        <div v-if="video.player_options.interaction_during_type == 'text'" class="row-space-top-3">
                            <div class="row-space-3 inputBox">
                                <label for="">Text</label>
                                <el-input type="textarea" v-model="video.player_options.interaction_during_text"
                                          placeholder="Enter text"></el-input>
                            </div>

                            <div class="row-space-3 inputBox">
                                <label for="">URL Link</label>
                                <el-input v-model="video.player_options.interaction_during_link_url"
                                          placeholder="Enter URL Link"></el-input>
                            </div>
                        </div>
                        <!-- /text -->

                        <!-- image -->
                        <div v-if="video.player_options.interaction_during_type == 'image'" class="row-space-top-3">
                            <label for="">Image</label>
                            <div class="row-space-3">
                                <image-input v-model="video.player_options.interaction_during_image"
                                             :user="user"></image-input>
                            </div>

                            <label for="">URL Link</label>
                            <div class="row-space-3">
                                <el-input v-model="video.player_options.interaction_during_link_url"
                                          placeholder="Enter URL Link"></el-input>
                            </div>
                        </div>
                        <!-- /image -->

                        <!-- html code -->
                        <div v-if="video.player_options.interaction_during_type == 'html_code'" class="row-space-top-3">
                            <label for="">HTML</label>
                            <div class="row-space-3">
                                <el-input type="textarea" placeholder="HTML Code"
                                          v-model="video.player_options.interaction_during_html_code"></el-input>
                            </div>
                        </div>
                        <!-- /html code -->

                        <hr>


                        <label for="">Options</label>
                        <div class="row-space-top-1 allow">
                            <el-checkbox v-model="video.player_options.interaction_during_allow_skip">Allow skip
                            </el-checkbox>
                        </div>

                    </div>
                </div>
            </div>
            <!-- /call to action during -->

            <hr class="clearfix">

            <!-- email capture during -->
            <div class="row-space-5">
                <label for="">Email Capture</label>
                <el-switch v-if='!email_capture_restrict' v-model="video.player_options.interaction_during_email_capture"
                           class="row-space-right-1"></el-switch>
                <span v-else style='position: relative'>
                    <popover v-if='showEmailRestrictPopover' message='Email capture is not included in your plan, please upgrade.' bottom='21px' width='230px'></popover>
                    <span @mouseenter="showEmailRestrictPopover = true" @mouseleave="showEmailRestrictPopover = false">
                        <el-switch></el-switch>
                    </span>
                </span>

                <div v-if="video.player_options.interaction_during_email_capture" class="row-space-top-3">
                    <el-input placeholder="00:00:00"
                              class="time timeBox"
                              :value="capture_time"
                              @input="changeInteractionDuringEmailCaptureTime($event)"
                              style="width: 50%;"
                    >
                        <template slot="prepend">Time</template>
                    </el-input>

                    <div class="row-space-top-3 inputBox">
                        <label for="">Type</label>
                        <br>
                        <el-radio v-model="video.player_options.interaction_during_email_capture_type" label="full">
                            Full
                        </el-radio>
                        <br>
                        <el-radio v-model="video.player_options.interaction_during_email_capture_type"
                                  label="minimized">Minimized <span class="mini-text">(desktop)</span>
                        </el-radio>
                    </div>

                    <div class="row-space-top-3 inputBox">
                        <label for="">Options</label>

                        <div class="">
                            <el-checkbox v-model="video.player_options.interaction_during_email_capture_firstname">
                                Firstname <span class="mini-text">(desktop)</span>
                            </el-checkbox>
                        </div>
                        <div class="">
                            <el-checkbox v-model="video.player_options.interaction_during_email_capture_lastname">
                                Lastname <span class="mini-text">(desktop)</span>
                            </el-checkbox>
                        </div>
                        <div class="">
                            <el-checkbox v-model="true_value" disabled>Email</el-checkbox>
                        </div>
                        <div class="">
                            <el-checkbox v-model="video.player_options.interaction_during_email_capture_phone_number">
                                Phone number <span class="mini-text">(desktop)</span>
                            </el-checkbox>
                        </div>
                        <div class="">
                            <el-checkbox v-model="video.player_options.interaction_during_email_capture_allow_skip">
                                Allow skip
                            </el-checkbox>
                        </div>
                    </div>

                    <hr>

                    <div>
                        <label for="">Upper Text</label>

                        <el-input type="textarea" class="row-space-top-1"
                                  v-model="video.player_options.interaction_during_email_capture_upper_text"></el-input>
                    </div>


                    <hr>

                    <div>
                        <label for="">Lower Text</label>

                        <el-input type="textarea" class="row-space-top-1"
                                  v-model="video.player_options.interaction_during_email_capture_lower_text"></el-input>
                    </div>

                    <hr>

                    <div>
                        <label for="">Button</label>

                        <el-input class="row-space-top-1"
                                  v-model="video.player_options.interaction_during_email_capture_button_text"></el-input>
                    </div>

                    <hr>

                    <div>
                        <label for="">Email List</label>

                        <div class="row-space-top-1">
                            <el-select
                                    v-model="video.player_options.interaction_during_email_capture_email_list"
                                    popper-class="custom_select_pop with_arrow_190"
                                    style="width:100%"
                                    placeholder="Select">
                                <el-option-group v-for="group in email_providers" :key="group.mailer"
                                                 :label="getNicename(group.mailer)">
                                    <el-option v-for="item in group.lists" :key="item.value" :label="item.name"
                                               :value="group.mailer + '_' + item.id">
                                    </el-option>
                                </el-option-group>
                            </el-select>
                        </div>
                    </div>

                    <hr>

                    <div class="">
                        <label for="">Tags
                            <span v-if="video.player_options.interaction_during_email_capture_email_tags">({{video.player_options.interaction_during_email_capture_email_tags.length}})</span>
                        </label>

                        <div class="row-space-top-1 interaction-tool">
                            <el-input placeholder="Enter tag" v-model="interaction_during_tag_input"
                                      @keyup.enter.native="addTag('during')" required>
                                <el-button slot="append" @click="addTag('during')" class="el-icon-plus"></el-button>
                            </el-input>
                        </div>

                        <div class="row-space-top-2">
                            <el-tag v-for="tag in video.player_options.interaction_during_email_capture_email_tags"
                                    v-bind:key="tag" closable @close="removeTag('during', tag)" type="success">{{tag}}
                            </el-tag>
                        </div>
                    </div>

                </div>
            </div>
            <!-- /email capture during-->
        </div>
        </div>

        <div v-if="activeLevel2 == 'interaction-after'">
            <BaseUpgradeModal :disableCancelButton='true' zIndex='4000' :showLimitAlert='showAfterRestrictionModal' messageBody='Please upgrade to unlock after video interaction options.'/>
            <div :class='showAfterRestrictionModal ? "blurred" : ""'>
            <p clas="tool_title">After Video</p>

            <p class="tool_description">Add call-to-action, capture emails and customize what happens after
                the video ends.</p>

            <div class="centerInputs" v-if='!showAfterRestrictionModal'>
                <div class="row-space-3 clearfix inputBox">
                    <label for="">Type</label>
                    <div class="">
                        <el-select
                                style="width:100%"
                                v-model="video.player_options.interaction_after_type"
                                popper-class="custom_select_pop with_arrow_190"
                                placeholder="Select">
                            <el-option v-for="item in after_interactions" :key="item.value"
                                       :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>

                <!-- more videos -->
                <div v-if="video.player_options.interaction_after_type == 'more_videos'">
                    <div class="inputBox">
                        <label for="">Videos</label>
                        <video-selector
                            multiple
                            :project="video.project"
                            :video="video"
                            v-model="video.player_options.interaction_after_more_videos_list"
                        ></video-selector>
                    </div>

                    <div class="row-space-top-3 inputBox" style="margin-top:30px">
                        <label for="">Text</label>
                        <el-input type="textarea"
                                  v-model="video.player_options.interaction_after_more_videos_text"></el-input>
                    </div>
                </div>
                <!-- /more videos -->
            </div>


            <!-- email capture after -->
            <div v-if="video.player_options.interaction_after_type == 'email_capture'"
                 class="row-space-top-3 row-space-5">
                <div>
                    <div class="inputBox">
                        <label for="">Type</label>
                        <br>
                        <el-radio v-model="video.player_options.interaction_after_email_capture_type" label="full">
                            Full
                        </el-radio>
                        <br>
                        <el-radio v-model="video.player_options.interaction_after_email_capture_type" label="minimized">
                            Minimized <span class="mini-text">(desktop)</span>
                        </el-radio>
                    </div>

                    <div class="inputBox">
                        <label for="">Options</label>

                        <div class="">
                            <el-checkbox v-model="video.player_options.interaction_after_email_capture_firstname">
                                Firstname <span class="mini-text">(desktop)</span>
                            </el-checkbox>
                        </div>
                        <div class="">
                            <el-checkbox v-model="video.player_options.interaction_after_email_capture_lastname">
                                Lastname <span class="mini-text">(desktop)</span>
                            </el-checkbox>
                        </div>
                        <div class="">
                            <el-checkbox v-model="true_value" disabled>Email</el-checkbox>
                        </div>
                        <div class="">
                            <el-checkbox v-model="video.player_options.interaction_after_email_capture_phone_number">
                                Phone number <span class="mini-text">(desktop)</span>
                            </el-checkbox>
                        </div>
                        <div class="">
                            <el-checkbox v-model="video.player_options.interaction_after_email_capture_allow_skip">Allow
                                skip
                            </el-checkbox>
                        </div>
                    </div>

                    <hr>

                    <div>
                        <label for="">Upper Text</label>

                        <el-input type="textarea" class="row-space-top-1"
                                  v-model="video.player_options.interaction_after_email_capture_upper_text"></el-input>
                    </div>


                    <hr>

                    <div>
                        <label for="">Lower Text</label>

                        <el-input type="textarea" class="row-space-top-1"
                                  v-model="video.player_options.interaction_after_email_capture_lower_text"></el-input>
                    </div>

                    <hr>

                    <div>
                        <label for="">Button</label>

                        <el-input class="row-space-top-1"
                                  v-model="video.player_options.interaction_after_email_capture_button_text"></el-input>
                    </div>

                    <hr>

                    <div>
                        <label for="">Email List</label>

                        <div class="row-space-top-1">
                            <el-select
                                    v-model="video.player_options.interaction_after_email_capture_email_list"
                                    popper-class="custom_select_pop with_arrow_190"
                                    placeholder="Select">
                                <el-option-group v-for="group in email_providers" :key="group.mailer"
                                                 :label="getNicename(group.mailer)">
                                    <el-option v-for="item in group.lists" :key="item.value" :label="item.name"
                                               :value="group.mailer + '_' + item.id">
                                    </el-option>
                                </el-option-group>
                            </el-select>
                        </div>
                    </div>

                    <hr>

                    <div class="">
                        <label for="">Tags
                            <span v-if="video.player_options.interaction_after_email_capture_email_tags">({{video.player_options.interaction_after_email_capture_email_tags.length}})</span>
                        </label>

                        <div class="row-space-top-1 interaction-tool">
                            <el-input placeholder="Enter tag" v-model="interaction_after_tag_input"
                                      @keyup.enter.native="addTag('after')" required>
                                <el-button slot="append" @click="addTag('after')" class="el-icon-plus"></el-button>
                            </el-input>
                        </div>

                        <div class="row-space-top-2">
                            <el-tag v-for="tag in video.player_options.interaction_after_email_capture_email_tags"
                                    v-bind:key="tag" closable @close="removeTag('after', tag)" type="success">{{tag}}
                            </el-tag>
                        </div>
                    </div>

                </div>
            </div>
            <!-- /email capture after-->

            <!-- call to action -->
            <div v-if="video.player_options.interaction_after_type == 'call_to_action'" class="">
                <label for="">Type</label>
                <div class="row-sapce-top-3 inputBox">
                    <el-select
                            v-model="video.player_options.interaction_after_cta_type"
                            popper-class="custom_select_pop with_arrow_190"
                            placeholder="Select">
                        <el-option
                                v-for="item in [{value: 'text', label: 'Text'}, {value: 'image', label:'Image'},{ value: 'html_code', label: 'HTML'}]"
                                :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>

                <!-- text -->
                <div v-if="video.player_options.interaction_after_cta_type == 'text'">
                    <label for="">Text</label>
                    <div class="inputBox">
                        <el-input type="text" v-model="video.player_options.interaction_after_cta_text"
                                  placeholder="Enter text"></el-input>
                    </div>

                    <label for="">URL Link</label>
                    <div class="inputBox">
                        <el-input v-model="video.player_options.interaction_after_cta_link_url"
                                  placeholder="Enter URL Link"></el-input>
                    </div>
                </div>
                <!-- /text -->

                <!-- image -->
                <div v-if="video.player_options.interaction_after_cta_type == 'image'" class="inputBox">
                    <label for="">Image</label>
                    <div class="row-space-3">
                        <image-input v-model="video.player_options.interaction_after_cta_image"
                                     :user="user"></image-input>
                    </div>

                    <label for="">URL Link</label>
                    <div class="row-space-3">
                        <el-input v-model="video.player_options.interaction_after_cta_link_url"
                                  placeholder="Enter URL Link"></el-input>
                    </div>
                </div>
                <!-- /image -->

                <!-- html code -->
                <div v-if="video.player_options.interaction_after_cta_type == 'html_code'" class="row-space-top-3">
                    <label for="">HTML</label>
                    <div class="row-space-3">
                        <el-input type="textarea" placeholder="HTML Code"
                                  v-model="video.player_options.interaction_after_cta_html_code"></el-input>
                    </div>
                </div>
                <!-- /html code -->
            </div>
            <!-- /call to action -->

            <!-- redirect -->
            <div v-if="video.player_options.interaction_after_type == 'redirect'" class="">
                <label for="">URL Link</label>

                <el-input type="url" v-model="video.player_options.interaction_after_cta_link_url"></el-input>
            </div>
            <!-- /redirect -->

        </div>
        </div>
    </div>
</template>
<style lang="less">
    .EditorInteractionTools {
      .mini-text{
        font-size: 13px;
        color: #696868e0;
      }
        margin-bottom: 70px;
        * {
            word-break: break-all
        }
        .el-input__inner, .el-select{
            width: 100%;
        }
        .time.el-input input {
            text-align: center;
        }
        .tool_description {
            margin-bottom: 25px;
        }
        .tool_title {
            font-size: 15px;
        }
        label {
            font-weight: 500;
        }
        .el-textarea {
            border: 1px solid #00ACDC;
            border-radius: 4px;
        }
        .timeBox {
            padding: 20px 0 40px;
        }
        .inputBox {
            margin-bottom: 25px;
        }
        .video-add div{
            color: #00ACDC;
        }
        .video-add {
            .video-thumbnail {
                background: #C8E9F224;
            }
            .video-thumbnail:hover {
                cursor: pointer;
            }
        }
        .videos-wrap{
            justify-content: space-between;
        }
        .video-selector-wrap {
            .video-item {
                margin-bottom: 30px;
                width: 155px;
                height: 90px;
            }
            .video-thumbnail {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
      .interaction-tool {
        .el-button [class*="el-icon-"]{
          color: inherit;
        }
      }
      .el-switch.is-checked .el-switch__core {
            border-color: #0AD688;
            background: #0AD688;
        }
        .centerInputs {
            label {
                font-size: 13px;
            }
            .allow {
                label {
                    font-size: 14px;
                }
            }
            .el-checkbox__inner {
                background: #21455E;
                border-color: #21455E;
            }
        }
    }

</style>
<script>
    import AppHelper from "../../utils/AppHelper";
    import Popover from '../Common/Popover';

   import VideoSelector from '../UI/VideoSelector.vue';
import ImageInput from '../UI/ImageInput.vue';
    export default {
        props: [
            'video',
            'user',
            'activeLevel2',
        ],

        inject: ['$validator'],

        data() {
            return {
                true_value: true,
                email_providers: [],
                during_time: '00:00:00',
                capture_time: '00:00:00',
                AppHelper: AppHelper,
                nicenames: {
                    'mailchimp': 'MailChimp',
                    'aweber': 'Aweber',
                    'getresponse': 'GetResponse',
                    'sendlane': 'SendLane',
                    'sendreach': 'SendReach',
                    'benchmark': 'Benchmark',
                    'infusionsoft': 'Infusionsoft',
                    'activecampaign': 'ActiveCampaign',
                    'gotowebinar': 'GoToWebinar',
                    'hubspot': 'HubSpot',
                    'campaignmonitor': 'CampaignMonitor',
                    'constantcontact': 'ConstantContact',
                    'icontact': 'iContact'
                },
                interaction_before_tag_input: '',
                interaction_during_tag_input: '',
                interaction_after_tag_input: '',
                loading: false,
                showRestrictionModal: false,
                email_capture_restrict: false,
                showEmailRestrictPopover: false,
                after_interactions: [
                    {value:'more_videos', label:'More Videos'},
                    {value:'email_capture', label:'Capture Email'},
                    {value:'call_to_action', label:'Call-to-Action'},
                    {value:'loop', label:'Loop the Video'},
                    {value:'show_last_frame', label:'Pause on the Last Frame'},
                    {value:'show_thumbnail', label:'Reset to the Thumbnail'},
                    {value:'redirect', label:'Redirect to a URL Link'}
                ],
                showAfterRestrictionModal: false,
                showDuringRestrictionModal: false,
            };
        },

        mounted() {
            this.during_time = AppHelper.convertSecondsToTime(this.video.player_options.interaction_during_time);
            this.capture_time = AppHelper.convertSecondsToTime(this.video.player_options.interaction_during_email_capture_time)
            this.loadEmailProviderList();
            this.getRestrictions();
        },

        components: {
            VideoSelector: VideoSelector,
            ImageInput: ImageInput,
            Popover: Popover,
        },

        methods: {
            changeInteractionDuringTime(e){
                console.log('fff--')

                this.during_time = e;
                this.video.player_options.interaction_during_time = AppHelper.convertTimeToSeconds(e);
            },
            changeInteractionDuringEmailCaptureTime(e){
                this.capture_time = e;
                this.video.player_options.interaction_during_email_capture_time = AppHelper.convertTimeToSeconds(e);
            },
            showPrompt:function(){
                    if(this.video.player_options.interaction_before_email_capture){
                        this.video.player_options.interaction_before_email_capture=false;
                        this.$message({

							message: 'Please Disable Text Overlay on Thumbnails!',
							type: 'error'
						});
                    }

            },
            required(val) {
                return false;
            },
            handleImageLibraryOpen(model) {
                this.$emit('open-image-library', model)
            },
            loadEmailProviderList() {
                var $this = this;
                this.axios({
                    url: 'integrations/lists',
                    method: 'GET'
                }).then(function (response) {
                    $this.email_providers = $this.email_providers.concat(response.data);
                    $this.providersLoaded = true;
                }).catch(function (response) {
                    $this.$message({
                        'showClose': true,
                        'message': 'An error occurred while retrieving email providers: ' + response.message,
                        type: 'error'
                    });
                });
            },
            getNicename(name) {
                if (this.nicenames[name] !== undefined) {
                    return this.nicenames[name];
                }
                return name;
            },
            addTag(where, e) {
                var value = this['interaction_' + where + '_tag_input'];
                if (value !== '') {
                    this['interaction_' + where + '_tag_input'] = '';
                    if (!this.video.player_options['interaction_' + where + '_email_capture_email_tags'])
                        this.video.player_options['interaction_' + where + '_email_capture_email_tags'] = [];
                    this.video.player_options['interaction_' + where + '_email_capture_email_tags'].push(value);
                }
            },
            removeTag(where, tag) {
                this.video.player_options['interaction_' + where + '_email_capture_email_tags'].splice(
                    this.video.player_options['interaction_' + where + '_email_capture_email_tags'].indexOf(tag), 1);
            },
            getRestrictions()
            {
                this.loading = true;
                this.axios.get('restrictions/index')
                    .then(response => {
                        this.loading = false;
                        if (response.data.lifetime) {
                            const data = response.data.lifetime;
                            const subModules = data['sub_modules'];
                            if (subModules.indexOf("leads_auto_tagging") > -1) {
                                this.email_capture_restrict = true;
                                this.after_interactions = this.after_interactions.filter(interaction => interaction.value != 'email_capture')
                            }
                            if (subModules.indexOf("after_interaction") > -1) {
                                this.showAfterRestrictionModal = true;
                            }
                            if (subModules.indexOf("during_interaction") > -1) {
                                this.showDuringRestrictionModal = true;
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.loading = false;
                    });
            },
            showUpgradeOptions() {
                this.$router.push({name: 'ChangeSubscriptionPlan', params: {'section': 'billing'}});
            }
        },
        watch: {
            video: {
                handler(video) {
                    let emailList = video.player_options.interaction_before_email_capture_email_list;
                    if (emailList == -1) {
                        this.video.player_options.interaction_before_email_capture_email_list = '';
                    }
                },
                deep: true,
            }
        }
    };
</script>
