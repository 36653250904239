<template>
    <div>

        <div v-show="activeLevel2 == 'player_skin'">
            <label>Player Skin</label>

            <p class="row-space-top-1">
                Pick any of the different player styles that best suites your brand.
            </p>
            <label for="" class="row-space-2">Classic</label>

            <!-- <div class=""> -->
            <!-- This condition below was part of random images, which was restricting all random images -->
            <!-- v-if="image.includes('360') || image.includes('medium')" -->
            <!-- <div class="thumbnail-item">
                <img src="../../assets/img/playerskin/classic.svg" class="player-skin-thumb"
                     :class="{'active': this.video.player_options.player_skin == 'classic'}"
                     @click="PlayerSkin('classic')">&nbsp
            </div>
        </div> -->
            <div style="display: flex" class="mt-4">
                <div class="mt-4">
                    <!-- This condition below was part of random images, which was restricting all random images -->
                    <span for="" class="row-space-2">AERO</span>
                    <div class="thumbnail-item mr-2">
                        <img @click="PlayerSkin('aero')"
                             :class="{'active': this.video.player_options.player_skin == 'aero'}"
                             src="../../assets/img/playerskin/aero.svg" class="player-skin-thumb">&nbsp
                    </div>

                </div>
                <div class="mt-4">
                    <!-- This condition below was part of random images, which was restricting all random images -->
                    <span for="" class="row-space-2 ">BOLD</span>
                    <div class="thumbnail-item ">
                        <img @click="PlayerSkin('bold')"
                             :class="{'active': this.video.player_options.player_skin == 'bold'}"
                             src="../../assets/img/playerskin/bold.svg" class="player-skin-thumb">&nbsp
                    </div>

                </div>
            </div>
            <div style="display: flex">
                <div class="mt-4">
                    <!-- This condition below was part of random images, which was restricting all random images -->
                    <span for="" class="row-space-2">CLEAN</span>
                    <div class="thumbnail-item mr-2">
                        <img @click="PlayerSkin('clean')"
                             :class="{'active': this.video.player_options.player_skin == 'clean'}"
                             src="../../assets/img/playerskin/clean.svg" class="player-skin-thumb">&nbsp
                    </div>

                </div>
                <div class="mt-4">
                    <!-- This condition below was part of random images, which was restricting all random images -->
                    <span for="" class="row-space-2 ">MODERN</span>
                    <div class="thumbnail-item ">
                        <img @click="PlayerSkin('modern')"
                             :class="{'active': this.video.player_options.player_skin == 'modern'}"
                             src="../../assets/img/playerskin/morden.svg" class="player-skin-thumb">&nbsp
                    </div>

                </div>
            </div>
        </div>

        <div v-show="activeLevel2 == 'appearance'">
            <b>Appearance</b>

            <p class="row-space-top-2 row-space-5">
                Customize the look of your player. Choose a color that matches your
                brand.
            </p>

            <label for>Player Color</label>

            <!-- <div class="row-space-5">
                      <el-color-picker v-model="video.player_options.color" @active-change="function(val) {video.player_options.color = val;}" size="medium"></el-color-picker>
                  </div> -->

            <div class="row-space-5 mb-3 ">
                <color-picker :control="activeLevel2" :color="video.player_options.color"
                              v-model="video.player_options.color" :video="video"></color-picker>

<!--                <el-switch-->
<!--                        v-if="!this.subscriptionData || this.subscriptionData.plan && this.subscriptionData.plan.id !== 'free'"-->
<!--                        v-model="video.player_options.disable_global_color"-->
<!--                        class="row-space-right-1"></el-switch>-->

<!--                <el-tooltip v-else content="You need to upgrade your account to use your own color." placement="top">-->
<!--                    <el-switch class="row-space-right-1" @mouseover="showRestrictionPopover = true"-->
<!--                               @mouseleave="showRestrictionPopover = false"></el-switch>-->
<!--                </el-tooltip>-->


<!--                Disable Global Player Color-->
            </div>

            <label for="" class="row-space-top-1">Branding</label>

            <p style="display: flext; justify-content: middle;" class="row-space-top-1">
                <el-switch v-if="user.can_change_branding" class="row-space-right-1"></el-switch>
                <el-tooltip v-else content="You need to upgrade your account to use your own logo." placement="top">

                    <el-switch v-model="video.player_options.branding_active"
                               class="row-space-right-1"></el-switch>
                </el-tooltip>

                Custom Player Logo
                <!--                <a href="/settings/stage" target="_blank" style="float: right;">Setup</a>-->
            </p>
            <!--            <div class="row-space-top-2 opacity-75 smaller">When enabled, your brand logo is shown on the video.</div>-->
            <div class="row-space-top-2 opacity-75 smaller">Choose a logo to show on your video (optional)</div>

            <div class="branding-logo-detail">
                <div class="branding-logo-position">
                    <div class="positions title">
                        <span>Logo Position</span>
                    </div>
                    <div class="positions">
                        <el-select
                                popper-class="custom_select_pop with_arrow_190"
                                placeholder="Select"
                                v-model="video.player_options.branding_logo_position">
                            <el-option v-for="item in [{value: 'bottom_left',label: 'Bottom Left'}, {
                                                value: 'bottom_right',label: 'Bottom Right'}, {
                                                value: 'top_left',label: 'Top Left'}, {
                                                value: 'top_right',label: 'Top Right'}]" :key="item.value"
                                       :label="item.label" :value="item.value"></el-option>
                        </el-select>

                    </div>
                </div>


                <div class="list-all-branding-logo">
                    <div>

                        <div class="brand-logo" v-for="ele in this.user.branding_logos">
                            <img :src="ele.logo" class="" @click="video.player_options.branding_logo_id = ele.id"
                                 :class="{'active': ele.id == video.player_options.branding_logo_id && video.player_options.branding_logo_id !== null }">

                            <div class="logo-options" @click="logoToEdit(ele)">
                                <el-dropdown
                                        @command="handleLogoCommand"
                                        trigger="click"
                                        class="dropdown-container"
                                        menu-align="end"
                                        :placement="'bottom'"

                                >
                                    <span class="el-dropdown-link"></span>
                                    <el-dropdown-menu
                                            slot="dropdown"
                                            class="video-settings-dropdown custom_dropdown custom_logo_options dropdown_bottom_200"
                                    >

                                        <el-dropdown-item command="replacelogo">
                                            <el-upload drag
                                                       :show-file-list="false"
                                                       :on-progress="handleBrandLogoUploadProgress"
                                                       :on-success="handleBrandLogoReplaceSuccess"
                                                       :before-upload="handleBeforeBrandLogoUpload"
                                                       :action="logoreplaceendpoint+ele.id">
                                                Replace Logo
                                            </el-upload>

                                        </el-dropdown-item>
                                        <el-dropdown-item command="deleteLogo" style="color: red">Delete Logo
                                        </el-dropdown-item>

                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="upload-branding-logo mt-4">
                    <el-upload drag
                               :show-file-list="false"
                               :on-progress="handleBrandLogoUploadProgress"
                               :on-success="handleBrandLogoUploadSuccess"
                               :before-upload="handleBeforeBrandLogoUpload"
                               :action="this.logoendpoint">

                        <div class="">
                            <div class="add_new_branding_logo">
                                + Upload New Logo
                            </div>
                        </div>

                    </el-upload>


                </div>
                <label class="text-danger small" v-if="invalidLogoFormat">
                    <img alt class="warning-icon" src="../../assets/img/auth/warning.svg"/>
                    Please upload a jpeg or png file.
                </label>
            </div>
            <!--            <label for="" class="mb-2 mt-2">Source Control</label>-->

            <!--            <div class="video-source-control">-->
            <!--                <div class="mb-2">-->
            <!--                    <el-switch v-model="video.player_options.hide_source_logo" active-color="#0AD688"-->
            <!--                               class="row-space-right-1"></el-switch>-->
            <!--                    <span class="ml-3">Hide Logo</span>-->
            <!--                </div>-->
            <!--                <div class="mb-2">-->
            <!--                    <el-switch v-model="video.player_options.hide_source_ads" active-color="#0AD688"-->
            <!--                               class="row-space-right-1"></el-switch>-->
            <!--                    <span class="ml-3">Hide Ads</span>-->
            <!--                </div>-->
            <!--                <div class="mb-2">-->
            <!--                    <el-switch v-model="video.player_options.hide_source_related_videos" active-color="#0AD688"-->
            <!--                               class="row-space-right-1"></el-switch>-->
            <!--                    <span class="ml-3">Hide Related Videos</span>-->
            <!--                </div>-->
            <!--                <div class="mb-2">-->
            <!--                    <el-switch v-model="video.player_options.hide_source_title_links" active-color="#0AD688"-->
            <!--                               class="row-space-right-1"></el-switch>-->
            <!--                    <span class="ml-3">Hide Video Titles & Links</span>-->
            <!--                </div>-->
            <!--            </div>-->

        </div>

        <div v-show="activeLevel2 == 'thumbnail'">
            <b>Thumbnail</b>
            <p class="row-space-top-2 row-space-5">Thumbnail is the image your viewers see first before clicking the
                play button to watch the video.</p>

            <editor-thumbnail-options :user="user" :video="video" v-on:open-image-library="handleImageLibraryOpen"
                                      :currentTime="currentTime"></editor-thumbnail-options>

            <div v-if="!this.subscriptionData || this.subscriptionData.plan && this.subscriptionData.plan.name !== 'Free Forever'">
                <hr class="row-space-5">

                <label for="">Text overlay</label>

                <label class="row-space-top-2" style="display: flex; align-items:center;">
                    <el-switch class="row-space-right-2" v-if="!video.player_options.interaction_before_email_capture"
                               v-model="video.player_options.text_overlay"></el-switch>
                    <el-switch class="row-space-right-2" v-else @change="showPrompt()"
                               v-model="video.player_options.text_overlay"></el-switch>
                    Enable Text Overlay
                </label>

                <p class="smaller row-space-top-3 opacity-75">Overlay text on the video thumbnail.</p>

                <textarea class="form-control" v-model="video.player_options.text_overlay_text"></textarea>
            </div>

        </div>

        <div v-show="activeLevel2 == 'controls'">
            <b>Controls</b>

            <p class="row-space-top-2 row-space-5">Select which video control options are available to the viewer.</p>

            <!--            <label for="">Autoplay</label>-->

            <!--            <div class="">-->
            <!--                <label class="row-space-top-2 row-space-3">-->
            <!--                    <el-switch active-color="#0AD688" v-model="video.player_options.autoplay"-->
            <!--                               class="row-space-right-1" @change="enableAutoplay($event)"></el-switch>-->
            <!--                    Enable Autoplay-->
            <!--                </label>-->
            <!--            </div>-->

            <!--            <p>Video will be played on page load. The audio is muted.</p>-->

            <hr>

            <label for="">Control Buttons</label>

            <div class="row-space-top-2 row-space-5 flex-direction-column">
                <label class="row-space-top-2 row-space-3 block">
                    <el-switch active-color="#0AD688" v-model="video.player_options.playbar"
                               class="row-space-right-1"></el-switch>
                    Playbar
                </label>
                <label class="row-space-top-2 row-space-3 block">
                    <el-switch active-color="#0AD688" v-model="video.player_options.volume_control"
                               class="row-space-right-1" :disabled="this.video.player_options.autoplay"></el-switch>
                    Volume
                </label>


                <label class="row-space-top-2 row-space-3 block">
                    <el-switch active-color="#0AD688" v-model="video.player_options.share_control"
                               class="row-space-right-1"></el-switch>
                    Share
                </label>
                <label class="row-space-top-2 row-space-3 block" v-if="video.is_audio == 0">
                    <el-switch active-color="#0AD688" v-model="video.player_options.force_hd"
                               class="row-space-right-1"></el-switch>
                    Force HD
                </label>
                <label class="row-space-top-2 row-space-3 block">
                    <el-switch active-color="#0AD688" v-model="video.player_options.mute_icon"
                               class="row-space-right-1"></el-switch>
                    Mute / Unmute Icon
                </label>
                <label class="row-space-top-2 row-space-3 block">
                    <el-switch active-color="#0AD688" v-model="video.player_options.disable_right_click"
                               class="row-space-right-1"></el-switch>
                    Disable right click
                </label>
                <!--                <label class="row-space-top-2 row-space-3 block">-->
                <!--                    <el-switch active-color="#0AD688" v-model="video.player_options.chapter_control"-->
                <!--                               class="row-space-right-1"></el-switch>-->
                <!--                    Chapter-->
                <!--                </label>-->
                <!--                <label class="row-space-top-2 row-space-3 block">-->
                <!--                    <el-switch active-color="#0AD688" v-model="video.player_options.subtitle_control"-->
                <!--                               class="row-space-right-1" @change="removeSubtitleInitialisation"></el-switch>-->
                <!--                    Subtitle-->
                <!--                </label>-->
                <label class="row-space-top-2 row-space-3 block">
                    <el-switch active-color="#0AD688" v-model="video.player_options.settings"
                               class="row-space-right-1"></el-switch>
                    Settings


                </label>
                <label v-if="video.player_options.settings" class="flex-direction-column" style="margin-left:35%">
                    <label class="row-space-top-2 row-space-3 block">
                        <el-checkbox class="custom-el-checkbox-green"
                                     v-model="video.player_options.speed_control"></el-checkbox>
                        Playback Speed
                    </label>
                    <label class="row-space-top-2 row-space-3 block" v-if="video.is_audio == 0">
                        <el-checkbox class="custom-el-checkbox-green"
                                     v-model="video.player_options.quality_control"></el-checkbox>
                        Quality
                    </label>
                </label>
                <label class="row-space-top-2 row-space-3 block">
                    <el-switch active-color="#0AD688" v-model="video.player_options.fullscreen_control"
                               class="row-space-right-1"></el-switch>
                    Fullscreen
                </label>
            </div>

            <hr>

            <label for="">Control Visibility</label>
            <p>Set when the control buttons are shown.</p>
            <div class="row-space-top-2 row-space-5">
                <el-select
                        popper-class="custom_select_pop with_arrow_190"
                        placeholder="Select"
                        v-model="video.player_options.control_visibility">
                    <el-option v-for="item in [{value: 'on_hover',label: 'Show on hover'}, {
                                                value: 'always',label: 'Always'}, {
                                                value: 'hide',label: 'Hide'}, {
                                                value: 'hide_progress',label: 'Hide progress bar'}]" :key="item.value"
                               :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>

        </div>

        <div v-show="activeLevel2 == 'privacy'">
            <label for="">Privacy</label>

            <p class="row-space-top-1">Choose who can watch, embed, comment or download this video.</p>

            <div class="row-space-5">
                <label class="row-space-top-2">
                    <label for="">Who can watch this video?</label>
                    <el-select
                            class="w-100"
                            placeholder="Select"
                            popper-class="custom_select_pop with_arrow_190"
                            v-model="video.player_options.permissions">
                        <el-option v-for="item in [{value: 'everyone', label: 'Anyone'},
                                                // {value: 'collaborators', label: 'Project collaborators'},
                                                {value: 'password', label: 'People with password'},
                                                 // { value: 'private_link', label: 'People with the Private link'}
                                                ]"
                                   :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </label>

                <div v-show="video.player_options.permissions == 'password'" class="row-space-top-2">
                    <div class="row-space-2">
                        <label for="">Password</label>
                        <el-input v-model="video.player_options.password"></el-input>
                    </div>

                    <div class="row-space-3">
                        <label for="">Button text</label>
                        <el-input v-model="video.player_options.password_button_text"></el-input>
                    </div>
                </div>
            </div>

            <label class="row-space-top-2 row-space-5">
                <label for="">Where can this Video be Embedded?</label>
                <el-select
                        class="w-100"
                        placeholder="Select"
                        popper-class="custom_select_pop with_arrow_190"
                        v-model="video.player_options.embed_settings">
                    <el-option v-for="item in [{value: 'any',label: 'Any website'}, {
                                                value: 'none',label: 'No website'}, {
                                                value: 'whitelisted_domains',label: 'Specific domains'}]"
                               :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </label>

            <label v-if="video.player_options.embed_settings === 'whitelisted_domains'"
                   class="row-space-top-2 row-space-5 w-100">
                <label for="">Domains</label>
                <editor-domains
                        :propDomain="video.player_options.whitelisted_domains !== '*' ? video.player_options.whitelisted_domains : []"></editor-domains>
            </label>

            <!--            <label class="row-space-top-2 row-space-5">-->
            <!--                <label for="">Who can comment on this video?</label>-->
            <!--                <el-select-->
            <!--                        class="w-100"-->
            <!--                        placeholder="Select"-->
            <!--                        popper-class="custom_select_pop with_arrow_190"-->
            <!--                        v-model="video.player_options.commenting_permissions">-->
            <!--                    <el-option v-for="item in [ /*{value: 'collaborators',label: 'Project collaborators'},*/ {-->
            <!--                                                value: 'everyone',label: 'Anyone'}]" :key="item.value"-->
            <!--                               :label="item.label" :value="item.value"></el-option>-->
            <!--                </el-select>-->
            <!--            </label>-->

            <hr>

            <label for="">Download</label>

            <div class="">
                <label class="row-space-top-2 row-space-3">
                    <el-switch v-model="video.player_options.allow_download" class="row-space-right-1"></el-switch>
                    Allow Viewers to Download Video
                </label>
            </div>

            <p>This applies to the Stage video page and anywhere the video is embedded. </p>

        </div>

        <div v-show="activeLevel2 == 'chapters'">
            <b>Chapters</b>

            <p class="row-space-top-2 row-space-5">User chapters to segment your videos and make it more accessible to
                your viewers.</p>

            <div class="list_chapter" id="example-1">
                <draggable
                        :list="video.video_chapters"
                        :disabled="!enabled"
                        class="list-group"
                        ghost-class="ghost"
                        @start="dragging = true"
                        @end="dragging = false"
                >
                    <div class="chapter" v-for="(item, index) in video.video_chapters" :key='index'>

                        <div class="inline-flex new-chapter-input ">
                            <div class="custom-el-input el-input el-input--small chapter-time-input">


                                <input type="text" name="" autocomplete="off" placeholder="Chapter time"
                                       class="el-input__inner" value="" @blur="validateTime(index, 'time')"
                                       v-model="video.video_chapters[index].time">

                                <label class="text-danger small" v-if="video.video_chapters[index].error">
                                    <img alt class="warning-icon" src="../../assets/img/auth/warning.svg"/>
                                    Enter valid time
                                </label>
                            </div>
                            <div class="custom-el-input el-input el-input--small chapter-title-input">
                                <input type="text" name="" autocomplete="off" placeholder="Chapter title"
                                       class="el-input__inner" value="" @blur="validateTime(index, 'title')"
                                       v-model="video.video_chapters[index].title">
                                <label class="text-danger small" v-if="video.video_chapters[index].titleError">
                                    <img alt class="warning-icon" src="../../assets/img/auth/warning.svg"/>
                                    Enter chapter title
                                </label>
                            </div>
                            <div class="">
                                <div class="chapter-remove-btn" @click="removeHtml(index)"><span><i
                                        class="el-dialog__close el-icon el-icon-close"></i></span></div>
                            </div>

                        </div>

                    </div>
                </draggable>
            </div>

            <div class="">
                <div class="add_new_chapter" @click="appendHtml">
                    + Add a chapter
                </div>
            </div>


        </div>

        <div v-show="activeLevel2 == 'subtitles'">
            <div class="upload-restriction-dialog-5" v-if='blockedModules.includes("subtitles")'>
                <div class="warn-sign"><img src="./../../../static/img/exclamation.svg" alt=""></div>
                <p>Your current plan does not include Subtitles.</p>
                <el-button round class="restriction_dialog_btn" type="success" @click="showUpgradeOptions"> See Upgrade
                    Options
                </el-button>
            </div>
            <div :class='blockedModules.includes("subtitles") ? "blurred" : ""'>
                <b>Subtitles</b>
                <p class="row-space-top-2 row-space-5">Dramatically increase the reach and engagement on your videos
                    using
                    subtitles.</p>

                <div v-if="this.editSrtFile">
                    <div>
                        <b>Edit subtitle</b>
                        <div class="pull-right download-edited-file" :class="{'original': this.fileType}">
                            <span @click="handleCommand('uploadTranslatedSubtitlesFile')" v-if="!this.fileType"><b>Upload a file</b></span>
                            <span @click="generateAndDownloadSrt" class="ml-3"><b>Download</b></span>
                        </div>


                        <el-dialog
                                title="UPLOAD TRANSLATED SUBTITLES FILE"
                                center
                                :width=" `40%`"
                                class="el-dialog--huge dialog_edit dialog_border_radius upload_subtitles_file"
                                :visible.sync="uploadTranslatedSubtitlesFile"
                                :modalAppendToBody="false"
                        >

                            <div class="relative">

                                <label>SRT file</label>
                                <div class="upload_srt_file" id="upload_srt_file">

                                    <div class="list_uploaded_file" v-if="showFile"
                                         v-for="(item, index) in uploadedFiles"
                                         :key='index'>
                                        <div>
                                            <img src="../../assets/img/Group_16840.png">
                                        </div>
                                        <div class="file_name">
                                            <label>{{item.name}}</label>
                                        </div>
                                        <div class="file_size">
                                            <label>{{bytesToSize(item.size)}}</label>
                                        </div>
                                        <div class="remove_file">
                                            <label @click="removeSrt"><img
                                                    src="../../assets/img/Group_12204.png"></label>
                                        </div>
                                    </div>

                                    <div v-if="showUploadArea">
                                        <el-upload drag
                                                   :show-file-list="false"
                                                   :on-progress="handleImageUploadProgress"
                                                   :on-success="handleImageUploadSuccess"
                                                   :before-upload="handleBeforeImageUpload"
                                                   :action="this.endpoint">

                                            <div class="inner">
                                                <img src="../../assets/img/srt.png" width="50" alt=""
                                                     v-if="invalidFileFormat">
                                                <img src="../../assets/img/srt_blue.png" width="50" alt="" v-else>
                                                &nbsp;&nbsp;&nbsp;&nbsp;Upload SRT File
                                            </div>
                                            <div v-show="upload_in_progress" class="inner">
                                                <div class="text-center">
                                                    <i class="el-icon-loading"></i>
                                                </div>
                                            </div>
                                            <label class="text-danger small" v-if="invalidFileFormat">
                                                <img alt class="warning-icon" src="../../assets/img/auth/warning.svg"/>
                                                Please upload a valid WEBvtt file.
                                            </label>

                                        </el-upload>
                                    </div>

                                </div>


                            </div>
                            <div slot="footer" class="dialog-footer">
                                <el-button @click="uploadTranslatedSubtitlesFile = false" type="gray">Cancel</el-button>
                                <el-button class="btn btn-primary" @click="uploadTranslatedSrtFileToEdit">Continue
                                </el-button>
                            </div>
                        </el-dialog>

                    </div>

                    <div class="add_new_subTitleCaption">
                        <el-input
                                v-model="subText"
                                type="textarea"
                                placeholder="Text"
                                rows="3"
                                cols="50"
                                class="resize_none"

                        ></el-input>
                        <div class="addCaptionBtn" @click="AddRemoveSubtitleCaption(0, 'add_first')">
                            <i class="el-dialog__close el-icon el-icon-plus"></i>
                        </div>
                    </div>

                    <draggable
                            :list="this.SrtCaptions"
                            :disabled="!enabled"
                            class="list-group"
                            ghost-class="ghost"
                            @start="dragging = true"
                            @end="dragging = false"
                    >
                        <div v-for="(caption, index) in this.SrtCaptions" class="list_srt_captions" :key='index'>

                            <div class="captionStartEndTime">
                                <div>
                                    <input type="text" name="" autocomplete="off" placeholder="Start time"
                                           class="el-input__inner" value=""
                                           v-model="SrtCaptions[index].start">
                                </div>
                                <div>
                                    <input type="text" name="" autocomplete="off" placeholder="End time"
                                           class="el-input__inner" value=""
                                           v-model="SrtCaptions[index].end">

                                </div>
                            </div>
                            <div class="captionText">
                                <el-input
                                        type="textarea"
                                        v-model="SrtCaptions[index].text"
                                        placeholder="Text"
                                        rows="3"
                                        class="resize_none"
                                ></el-input>

                            </div>
                            <div class="captionActionIcons">
                                <div style="margin: 8px 0px;">
                                    <i class="el-dialog__close el-icon el-icon-close"
                                       @click="AddRemoveSubtitleCaption(index,'remove')"></i>
                                    <i class="el-dialog__close el-icon el-icon-plus"
                                       @click="AddRemoveSubtitleCaption(index,'add')"></i>
                                </div>
                            </div>

                        </div>
                    </draggable>

                </div>
                <div v-else>

                    <label for="">Original language</label>
                    <p class="row-space-top-2 row-space-5">This is the language spoken in your video.</p>
                    <div class="fix-10-top text-center" v-if="this.video.video_subtitles == null">
                        <a href="#" class="btn btn-primary" style="margin-top: 20px;margin-left: 10px;"
                           @click="handleCommand('generateSubtitlesFile')" :disabled="this.disabled">{{this.generateBtnText}}</a>
                        <br>
                        <a href="#" style="" @click="handleCommand('uploadSubtitlesFile')">or upload subtitle file</a>
                    </div>
                    <div class="fix-10-top" v-else>


                        <div class="text-center"
                             v-if="this.video.video_subtitles !== null && this.video.video_subtitles.sub_status !== 'completed'">
                            <a href="#" class="btn btn-primary" style="margin-top: 20px;margin-left: 10px;"
                               @click="handleCommand('generateSubtitlesFile')" :disabled="this.disabled">{{this.generateBtnText}}</a>
                            <br>
                            <a href="#" style="" @click="handleCommand('uploadSubtitlesFile')">or upload subtitle
                                file</a>
                        </div>

                        <div v-else>

                            <div class="video-language">
                        <span>
                            {{this.video.video_subtitles.lang_name}}
                            <el-dropdown

                                    trigger="click"
                                    class="dropdown-container detail_drop_down"
                                    menu-align="end"
                                    placement="bottom"
                            >
                                    <i class="el-icon-more "></i>
                                    <el-dropdown-menu slot="dropdown"
                                                      class="video-settings-dropdown custom_dropdown dropdown_bottom_200">
                                        <el-dropdown-item
                                                @click.native="editTranslatedFile(video.video_subtitles.id, 'original')">Edit
                                        </el-dropdown-item>
                                        <el-dropdown-item
                                                @click.native="generateAndDownloadSrt(video.video_subtitles.url)">Download
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="removeVideoSrtFile(video.video_subtitles.id)">Remove
                                        </el-dropdown-item>

                                    </el-dropdown-menu>

                                </el-dropdown>
                        </span>
                            </div>
                        </div>


                    </div>
                    <label for="">Translations</label>
                    <p class="row-space-top-2 row-space-5">Reach audiences that do not speak your languange. subtitle
                        your
                        video
                        in different languages.</p>


                    <div v-if="this.video.video_subtitles == null">
                        <div class="translation_notice">
                            <img alt class="warning-icon" src="../../assets/img/Group_16918.png"/> Add original
                            subtitles
                            first
                            before translation.
                        </div>
                    </div>

                    <div class="" v-else>

                        <div v-for="(data, index) in this.video.video_subtitles.translated_subtitles" :key='index'>
                            <div class="video-language">
                                <span>{{data.lang_name}}</span>

                                <el-dropdown

                                        trigger="click"
                                        class="dropdown-container detail_drop_down"
                                        menu-align="end"
                                        placement="bottom"
                                >
                                    <i class="el-icon-more "></i>
                                    <el-dropdown-menu slot="dropdown"
                                                      class="video-settings-dropdown custom_dropdown dropdown_bottom_200">
                                        <el-dropdown-item @click.native="editTranslatedFile(data.id, 'translated')">Edit
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="generateAndDownloadSrt(data.url)">Download
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="removeTranslatedFile(data.id)">Remove
                                        </el-dropdown-item>

                                    </el-dropdown-menu>

                                </el-dropdown>
                            </div>
                        </div>

                        <div class="add_new_sub_language">
                            <!--                    <div class="add_new_subtitle_language">-->
                            <!--                        + Add language-->
                            <!--                    </div>-->
                            <el-dropdown
                                    @command="handleLanguageCommand"
                                    trigger="click"
                                    class="dropdown-container"
                                    menu-align="end"
                                    placement="bottom"
                            >
                                <span class="el-dropdown-link add_new_subtitle_language">+ Add language</span>
                                <el-dropdown-menu slot="dropdown"
                                                  class="video-settings-dropdown language-dropdown custom_dropdown dropdown_bottom_200">
                                    <el-dropdown-item v-for="(region, index) in languages" :command="region.code"
                                                      :key='index'>
                                        {{region.name}}
                                    </el-dropdown-item>

                                </el-dropdown-menu>

                            </el-dropdown>

                            <el-dialog
                                    title="SUBTITLE TRANSLATION"
                                    center
                                    :width="`40%`"
                                    class="el-dialog--huge dialog_edit dialog_border_radius upload_subtitles_file"
                                    :visible.sync="subtitlesTranslation"
                                    :modalAppendToBody="false"
                            >
                                <div class="relative mb-3 ">
                                    <div>
                                        <div class="generate_notice">
                                            <img alt class="warning-icon" src="../../assets/img/auth/warning.svg"/>
                                            <span>Confirm this is the correct language you want to subtitle?</span>
                                        </div>
                                    </div>
                                    <label>Language</label>
                                    <div class="row-space-top-1 el-input">

                                        <el-select

                                                :placeholder="placeholder"
                                                :value="selLanguage"
                                                @change="onChange()"
                                                filterable
                                                v-model="selLanguage"
                                                class="custom_select"
                                                popper-class="custom_select_pop admin_select_pop location_select_pop"
                                        >
                                            <el-option
                                                    :label="region.name"
                                                    :value="region[valueType]"
                                                    v-for="(region, index) in languages"
                                                    :key='index'
                                            ></el-option>
                                        </el-select>

                                    </div>
                                </div>

                                <div slot="footer" class="dialog-footer">
                                    <el-button @click="subtitlesTranslation = false" type="gray">Cancel</el-button>
                                    <el-button class="btn btn-primary" @click="translateSrt"
                                               :disabled="this.translateBtnDisabled">{{this.translateBtnText}}
                                    </el-button>
                                </div>
                            </el-dialog>


                        </div>
                    </div>


                    <el-dialog
                            title="UPLOAD SUBTITLES FILE"
                            center
                            :width=" `40%`"
                            class="el-dialog--huge dialog_edit dialog_border_radius upload_subtitles_file"
                            :visible.sync="uploadSubtitlesFile"
                            :modalAppendToBody="false"
                    >
                        <div class="relative mb-3 ">
                            <label>Title</label>
                            <div class="row-space-top-1 el-input">

                                <el-select
                                        :placeholder="placeholder"
                                        :value="selLanguage"
                                        @change="onChange()"
                                        filterable
                                        v-model="selLanguage"
                                        class="custom_select"
                                        popper-class="custom_select_pop admin_select_pop location_select_pop"
                                >
                                    <el-option
                                            :label="region.name"
                                            :value="region[valueType]"
                                            v-for="(region, index) in languages"
                                            :key='index'
                                    ></el-option>
                                </el-select>
                                <label class="text-danger small" v-if="laguageError">Please select language</label>

                            </div>
                        </div>
                        <div class="relative">

                            <label>SRT file</label>
                            <div class="upload_srt_file" id="upload_srt_file">

                                <div class="list_uploaded_file" v-if="showFile" v-for="(item, index) in uploadedFiles"
                                     :key='index'>
                                    <div>
                                        <img src="../../assets/img/Group_16840.png">
                                    </div>
                                    <div class="file_name">
                                        <label>{{item.name}}</label>
                                    </div>
                                    <div class="file_size">
                                        <label>{{bytesToSize(item.size)}}</label>
                                    </div>
                                    <div class="remove_file">
                                        <label @click="removeSrt"><img src="../../assets/img/Group_12204.png"></label>
                                    </div>
                                </div>

                                <div v-if="showUploadArea">
                                    <el-upload drag
                                               :show-file-list="false"
                                               :on-progress="handleImageUploadProgress"
                                               :on-success="handleImageUploadSuccess"
                                               :before-upload="handleBeforeImageUpload"
                                               :action="this.endpoint">

                                        <div class="inner">

                                            <img src="../../assets/img/srt.png" width="50" alt=""
                                                 v-if="invalidFileFormat">
                                            <img src="../../assets/img/srt_blue.png" width="50" alt="" v-else>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Upload SRT File
                                        </div>
                                        <div v-show="upload_in_progress" class="inner">
                                            <div class="text-center">
                                                <i class="el-icon-loading"></i>
                                            </div>
                                        </div>
                                        <label class="text-danger small" v-if="invalidFileFormat">
                                            <img alt class="warning-icon" src="../../assets/img/auth/warning.svg"/>
                                            Please upload a SRT file.
                                        </label>
                                    </el-upload>
                                    <label class="text-danger small" v-if="fileError">Please select file</label>
                                </div>
                            </div>
                        </div>
                        <div slot="footer" class="dialog-footer">
                            <el-button @click="uploadSubtitlesFile = false" type="gray">Cancel</el-button>
                            <el-button class="btn btn-primary" @click="uploadSrtFile" :disabled="this.disabled">
                                {{this.importBtnText}}
                            </el-button>
                        </div>
                    </el-dialog>

                    <el-dialog
                            title="GENERATE SUBTITLES"
                            center
                            :width="`40%`"
                            class="el-dialog--huge dialog_edit dialog_border_radius upload_subtitles_file"
                            :visible.sync="generateSubtitlesFile"
                            :modalAppendToBody="false"
                    >
                        <div class="relative mb-3 ">
                            <div>
                                <div class="generate_notice">
                                    <img alt class="warning-icon" src="../../assets/img/auth/warning.svg"/>
                                    <span>Select the correct language in your video to accurate captioning</span>
                                </div>
                            </div>
                            <label>Language</label>
                            <div class="row-space-top-1 el-input">

                                <el-select
                                        :placeholder="placeholder"
                                        :value="selLanguage"
                                        @change="onChange()"
                                        filterable
                                        v-model="selLanguage"
                                        class="custom_select"
                                        popper-class="custom_select_pop admin_select_pop location_select_pop"
                                >
                                    <el-option
                                            :label="region.name"
                                            :value="region[valueType]"
                                            v-for="(region, index) in languages"
                                            :key='index'
                                    ></el-option>
                                </el-select>

                            </div>
                        </div>

                        <div slot="footer" class="dialog-footer">
                            <el-button @click="generateSubtitlesFile = false" type="gray">Cancel</el-button>
                            <el-button class="btn btn-primary" @click="generateSrtFromVideo">{{this.generateBtnText}}
                            </el-button>
                        </div>
                    </el-dialog>

                </div>
            </div>
        </div>

        <div v-show="activeLevel2 == 'stage'">
            <BaseUpgradeModal
                    :messageHead='showStageWarning.head'
                    :messageBody='showStageWarning.body'
                    :showLimitAlert='showStageWarning.show'
                    position='absolute'
                    zIndex='2000'
                    :disableCancelButton='true'
            />
            <label for="">Stage settings</label>

            <p class="row-space-top-1">Publish this video as a native post on your Social
                Media platforms and track stats from Analytics.</p>

            <div class="row-space-top-3 switcher_custom_div" v-if='!showStageWarning.show'>
                <label>
                    <el-switch
                            v-model="video.published_on_stage"
                            class="row-space-right-1 el-icon-close el-icon-check custom_switchers"
                            active-color="#0AD688"
                            inactive-color="red"
                    ></el-switch>
                    Publish this Video on Stage
                </label>
            </div>

            <div class="row-space-top-2 switcher_custom_div" v-if='!showStageWarning.show'>
                <label for="">
                    <el-switch
                            v-model="video.featured_on_stage"
                            class="row-space-right-1 el-icon-close el-icon-check custom_switchers"
                            active-color="#0AD688"
                            inactive-color="red"

                    ></el-switch>
                    Feature this Video on Stage
                </label>
            </div>
        </div>

        <div v-show="activeLevel2 == 'video_behaviour'">
            <label for="">Video Behaviours</label>

            <p class="row-space-top-1">Customize the look of your player. Choose a color that matches your brand</p>

            <div class="mt-4">
                <div class="video-behaviour-options">
                    <div class="mb-2">
                        <el-switch v-model="video.player_options.resume_playback" active-color="#0AD688"
                                   class="row-space-right-1"></el-switch>
                        <span class="ml-3">Resume Playback</span>
                    </div>
                    <div class="mb-2">
                        <el-switch v-model="video.player_options.sticky_player" active-color="#0AD688"
                                   class="row-space-right-1"
                                   @change="pausePlayer(video.player_options.sticky_player, 'sticky')"></el-switch>
                        <span class="ml-3">Sticky Floating Player</span>
                        <br>
                        <div class="inner-behaviour-option" v-if="video.player_options.sticky_player">
                            <el-input v-model="message" class="sticky-pause-input"></el-input>

                            <span style="cursor: pointer" v-clipboard:copy="message" v-clipboard:success="onCopy"
                                  v-clipboard:error="onError">Copy</span>

                        </div>


                    </div>
                    <div class="mb-2">
                        <el-switch v-model="video.player_options.smart_pausing" active-color="#0AD688"
                                   class="row-space-right-1"
                                   @change="pausePlayer(video.player_options.smart_pausing, 'pause')"></el-switch>
                        <span class="ml-3">Smart Pausing</span>
                        <br>
                        <div class="inner-behaviour-option" v-if="video.player_options.smart_pausing">
                            <el-input v-model="message" class="sticky-pause-input"></el-input>
                            <span style="cursor: pointer" v-clipboard:copy="message" v-clipboard:success="onCopy"
                                  v-clipboard:error="onError">Copy</span>
                        </div>
                    </div>
                    <div class="mb-2">
                        <el-switch v-model="video.player_options.autoplay" active-color="#0AD688"
                                   class="row-space-right-1" @change="enableAutoplay($event)"></el-switch>
                        <span class="ml-3">AutoPlay on Page Load</span>
                    </div>
                    <div class="mb-2">
                        <el-switch v-model="video.player_options.disable_autoplay" active-color="#0AD688"
                                   class="row-space-right-1"></el-switch>
                        <span class="ml-3">Disable Auto Play After X Visits</span>

                        <div class="inner-behaviour-option" v-if="video.player_options.disable_autoplay">
                            <span>After</span>
                            <el-input :value="2" v-model="video.player_options.disable_autoplay_visits"></el-input>
                            <span>Visits</span>
                        </div>

                    </div>
                    <div class="mb-2">
                        <el-switch v-model="video.player_options.custome_start_end_time" active-color="#0AD688"
                                   class="row-space-right-1"></el-switch>
                        <span class="ml-3">Custom Start & End Time</span>
                        <div class="inner-behaviour-option" v-if="video.player_options.custome_start_end_time">
                            <span>Start</span>
                            <el-input placeholder="00:00:00"
                                      v-model="video.player_options.custome_start_time"></el-input>
                            <span>End</span>
                            <el-input placeholder="00:00:00" v-model="video.player_options.custome_end_time"></el-input>
                        </div>
                    </div>
                    <div class="mb-2">
                        <el-switch v-model="video.player_options.disable_pause" active-color="#0AD688"
                                   class="row-space-right-1"></el-switch>
                        <span class="ml-3">Disable Pause</span>
                    </div>
                    <div class="mb-2">
                        <el-switch v-model="video.player_options.mimic_live_stream" active-color="#0AD688"
                                   class="row-space-right-1"></el-switch>
                        <span class="ml-3">Mimic Live Streaming</span>
                        <br>
                        <div class="inner-behaviour-option" v-if="video.player_options.mimic_live_stream">
                            <span>Display Icon</span>
                            <el-select
                                    class="live-icon-position"
                                    popper-class="custom_select_pop with_arrow_190"
                                    placeholder="Select"
                                    v-model="video.player_options.live_stream_position">
                                <el-option v-for="item in [{value: 'bottom_left',label: 'Bottom Left'}, {
                                                value: 'bottom_right',label: 'Bottom Right'}, {
                                                value: 'top_left',label: 'Top Left'}, {
                                                value: 'top_right',label: 'Top Right'}]" :key="item.value"
                                           :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div v-show="activeLevel2 == 'video_funnel'">

            <b>Dynamic Video Funnels</b>

            <p class="row-space-top-2 row-space-5">Build video funnels and personalize what your viewer see based on
                their choices.</p>
            <div class="mb-2 mt-4 dynamic-video-funnel">
                <el-switch v-model="video.player_options.enable_video_funnel" active-color="#0AD688"
                           class="row-space-right-1"></el-switch>
                <span class="ml-3 ">Enable</span>

                <div v-if="video.player_options.enable_video_funnel">
                    <div class="mb-3 mt-4">
                        <el-input placeholder="00:00:00"
                                  class="time"
                                  :value="funnel_time"
                                  @input="changeFunnelTime($event)"
                                  style="width: 50%;"
                                  pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                        >
                            <template slot="prepend">Time</template>
                        </el-input>
                    </div>
                    <div class="row-space-top-3 mb-3">
                        <div class="row-space-3 inputBox">
                            <label for="">Text</label>
                            <el-input type="textarea"
                                      v-model="video.player_options.funnel_text"
                                      placeholder="Enter text"></el-input>
                        </div>

                    </div>
                    <div class="row-space-top-3 mb-3">

                        <div class="funnel-videos" :key="v.id" @click="configureFunnelVideo(v, video.id)"
                             v-for="(v, i) in video.child_videos">

                            <span class="">{{v.title}}</span>


                        </div>


                    </div>
                    <div class="">
                        <div class="add_new_child_logo" @click="addNewBranchVideo()">
                            + Add New Child Video
                        </div>
                    </div>
                </div>
            </div>


        </div>


        <funnel-integration-modal @store='storeBranchVideo' @trigger='loadFunnelVideoAction = ""'
                                  v-if="this.loadFunnelVideoAction !== ''" :type="this.loadFunnelVideoAction"
                                  :video="videoToAddFunnel" :user="user"></funnel-integration-modal>


        <el-dialog
                :title="'Configure ' + videoToAddFunnel.title"
                center
                :width="`50%`"
                class="el-dialog--full el-dialog--large dialog_edit dialog_border_radius funneldetailDilogue"
                :visible.sync="editChildVideo"
                :modalAppendToBody="false"
        >


            <div v-show="videoLoaded" class="add-edit-video-funnel">

                <div class="video-config-head">
                    <div class="inner-video-config-head card-text">
                        <div style="width: 100%">

                            <span class="title">Video Card Text</span>
                            <el-input v-model="videoToAddFunnel.player_options.video_card_text"></el-input><br>
                        </div>
                        <div>
                            <span v-if="validateVideo.card_text" class="error-msg">{{validateVideo.message}}</span>
                        </div>

                    </div>
                    <div class="inner-video-config-head text-color">
                        <span class="title">Text Color</span>
                        <div>
                            <funnel-color-picker :control="'video_funnel'"
                                                 :video="videoToAddFunnel"
                                          :color="videoToAddFunnel.player_options.video_text_color"
                                          v-model="videoToAddFunnel.player_options.video_text_color"></funnel-color-picker>

<!--                            <color-picker :control="activeLevel2" :color="video.player_options.color"-->
<!--                                          v-model="video.player_options.color" :video="video"></color-picker>-->
                        </div>
                    </div>
                    <div class="inner-video-config-head background-color">
                        <span class="title">Background Color</span>
                        <div>
                            <background-color-picker :control="'video_funnel'"
                                                     :video="videoToAddFunnel"
                                                     :color="videoToAddFunnel.player_options.video_background_color"
                                                     v-model="videoToAddFunnel.player_options.video_background_color"></background-color-picker>
                        </div>
                    </div>
                </div>
                <div class="video-config-url">
                    <span class="title">Video URL</span>
                    <div>
                        <el-input placeholder="Please input" class="input-with-select" v-model="videoToAddFunnel.path">
                            <el-select slot="prepend" v-model="videoToAddFunnel.power_player_type"
                                       :placeholder="videoToAddFunnel.power_player_type"
                                       :style="{'width': 150+'px'}">
                                <el-option label="YOUTUBE" value="YOUTUBE"></el-option>
                                <el-option label="MP4/WEBM" value="MP4/WEBM"></el-option>
                                <el-option label="WISTIA" value="WISTIA"></el-option>
                                <el-option label="VIMEO" value="VIMEO"></el-option>
                                <el-option label="M3U8/MPD" value="M3U8/MPD"></el-option>
                                <el-option label="LIVE STREAMING" value="LIVE STREAMING"></el-option>
                            </el-select>
                            <!--                                        <el-button slot="append" icon="el-icon-search"></el-button>-->
                        </el-input>
                        <span v-if="validateVideo.path" class="error-msg">{{validateVideo.message}}</span>
                    </div>
                </div>
                <div class="video-config-interaction mt-4 mb-3">
                    <div class="during-video-interaction">
                        <div class="inner-during-video-interaction">
                            <span class="text-blue">During Video Interaction</span>
                        </div>
                        <div class="inner-during-video-interaction">
                            <span>
                                Call-To-Action
                            </span>
                            <div>
                                <label class="row-space-top-2 row-space-3 block">
                                    <el-switch active-color="#0AD688"
                                               v-model="videoToAddFunnel.player_options.interaction_during_active"
                                               class="row-space-right-1"
                                               @change="actionType('during_video_call_to_action', $event)"></el-switch>
                                    Enable Call-To-Action
                                </label>
                            </div>
                        </div>
                        <div class="inner-during-video-interaction">
                            <span>
                                Email Capture
                            </span>
                            <div>
                                <label class="row-space-top-2 row-space-3 block">
                                    <el-switch active-color="#0AD688"
                                               v-model="videoToAddFunnel.player_options.interaction_during_email_capture"
                                               class="row-space-right-1"
                                               @change="actionType('during_video_email', $event)"></el-switch>
                                    Enable Email Capture
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="during-video-interaction mt-4">
                        <div class="inner-during-video-interaction">
                            <span class="" @click="loadFunnelVideoAction = 'after_video'">After Video Interaction</span>
                        </div>
                        <div class="inner-during-video-interaction">

                        </div>
                        <div class="inner-during-video-interaction">

                        </div>
                    </div>
                </div>
                <div class="mb-2 mt-4 dynamic-video-funnel">
                    <el-switch v-model="videoToAddFunnel.player_options.enable_video_funnel" active-color="#0AD688"
                               class="row-space-right-1"></el-switch>
                    <span class="ml-3 ">Enable Branching</span>
                </div>
                <div class="mb-2 mt-4 dynamic-video-funnel" v-if="videoToAddFunnel.player_options.enable_video_funnel">


                    <div class="mb-3 mt-4">
                        <el-input placeholder="00:00:00"
                                  class="time timeBox"
                                  style="width: 50%;"
                                  v-model="videoToAddFunnel.player_options.video_funnel_time"
                        >
                            <template slot="prepend">Time</template>
                        </el-input>
                    </div>
                    <div class="row-space-top-3 mb-3">
                        <div class="row-space-3 inputBox">
                            <label for="">Text</label>
                            <el-input type="textarea"
                                      placeholder="Enter text"
                                      v-model="videoToAddFunnel.player_options.funnel_text"></el-input>
                        </div>

                    </div>
                    <div class="row-space-top-3 mb-3">
                        <div class="funnel-videos" :key="v.id" @click="configureFunnelVideo(v, videoToAddFunnel.id)"
                             v-for="(v, i) in videoToAddFunnel.child_videos">
                            <span class="text-white">{{v.title}}</span>
                        </div>
                    </div>
                    <div class="">
                        <div class="add_new_child_logo" @click="addBranchVideo()">
                            + Add New Child Video
                        </div>
                    </div>
                </div>

            </div>
            <div v-show="!videoLoaded" class="inner">
                <div class="text-center">
                    <i class="el-icon-loading"></i>
                </div>
            </div>


            <div slot="footer" class="dialog-footer">
                <button class="el-button el-button--gray" @click="editChildVideo = false" type="gray">Cancel</button>
<!--                <button class="el-button el-button&#45;&#45;gray preview-funnel" @click="editChildVideo = false" type="gray">-->
<!--                    Preview-->
<!--                </button>-->
                <span class="btn btn-primary" @click="storeBranchVideo" :disabled="disabledBtn">Apply</span>
            </div>
        </el-dialog>


        <BaseLoader
                message='Please Wait..'
                :showLoader='loading'
        />
        <div class="content_security" v-show="activeLevel2 == 'content_security'">
            <div :class="blockedModules.includes('advanced_content_security') ? 'blurred' : ''">
                <label for="">Content Security</label>
                <p class="row-space-top-1">Advanced features to protect your digital rights.</p>
                <div v-if="VisualWatermarkContent">
                    <div>

                        <label class="row-space-top-2 row-space-3 block">
                            <el-switch active-color="#0AD688" v-model="video.player_options.visual_watermark_ip"
                                       class="row-space-right-1"></el-switch>
                            IP Address
                        </label>
                    </div>
                    <div>
                        <label class="row-space-top-2 row-space-3 block">
                            <el-switch active-color="#0AD688" v-model="video.player_options.visual_watermark_timestamp"
                                       class="row-space-right-1"></el-switch>
                            Timestamp
                        </label>
                    </div>
                    <div>
                        <!--                    <label class="row-space-top-2 row-space-3 block">-->
                        <!--                        <el-switch active-color="#0AD688" v-model="video.player_options.visual_watermark_email"-->
                        <!--                                   class="row-space-right-1"></el-switch>-->
                        <!--                        Email address(inject)-->
                        <!--                    </label>-->
                        <label class="row-space-top-2 row-space-3 block">
                            <el-switch active-color="#0AD688" v-model="video.player_options.deter_text"
                                       class="row-space-right-1"></el-switch>
                            Deter Text
                        </label>
                        <div v-if="video.player_options.deter_text" class="mt-3 mb-3">
                            <input type="text" name="" autocomplete="off" placeholder="Deter text"
                                   class="el-input__inner" value=""
                                   v-model="video.player_options.deterText">
                        </div>
                    </div>
                    <div>
                        <label class="row-space-top-2 row-space-3 block">
                            <el-switch active-color="#0AD688" v-model="video.player_options.visual_watermark_name"
                                       class="row-space-right-1"></el-switch>
                            Full name(inject)
                        </label>
                    </div>
                    <div>
                        <label class="row-space-top-2 row-space-3 block">
                            <el-switch active-color="#0AD688" v-model="video.player_options.geo_location"
                                       class="row-space-right-1"></el-switch>
                            Geo Location
                        </label>
                    </div>
                    <!--<div style="margin-top: auto;-->
                    <!--    display: flex;-->
                    <!--    justify-content: center;-->
                    <!--    z-index: 899;">-->

                    <!--    <el-button-->
                    <!--            type="gray"-->
                    <!--            name="button"-->
                    <!--            class="btn btn-info"-->
                    <!--            @click="VisualWatermarkContent = false"-->
                    <!--            style=""-->
                    <!--    >Back</el-button>-->
                    <!--</div>-->

                </div>
                <div v-else>
                    <label class="label_with_bg" for="">DRM AND AES PROTECTION
                        <span v-if="video.drm_protection" class="cs_status active">ACTIVE</span>
                        <span v-else class="cs_status">DISABLED</span>
                    </label>

                    <p class="row-space-top-1">Digital Rights Management (DRM) and Advanced Encryption Standard (AES)
                        securely encrypt your video during encoding and packaging to prevent
                        unauthorized download and distibution of your premium contents.</p>
                    <label for="">Sessions protected</label>
                    <div class="session_count">
                        <span class="sum-number"
                              v-if="video.drm_sessions_count !== null">{{ video.drm_sessions_count }}</span>
                        <span class="sum-number" v-else>0</span>

                    </div>
                    <!-- <label class="label_with_bg" for="">FORENSIC WATERMARKETING
                        <span v-if="video.forensic_watermarking" class="cs_status active">ACTIVE</span>
                        <span v-else class="cs_status">DISABLED</span>
                    </label>
                    <p class="row-space-top-1" :class='user.forensic_watermarking != 1 ? "blurred" : ""'>Inject <strong>session-based, invisible, tracked</strong> metadata such as
                        viewer's browser
                        fingerprint, name or IP address into your video streams that can be used to trace source of any
                        privacy/copyright violation.
                        This protects your premium content from advanced attacts such as camera recording, screen capture,
                        ripping, transcoding etc.
                    </p>
                    <div style='position: relative;' v-if='user.forensic_watermarking != 1'>
                        <div class="upload-restriction-dialog-5">
                            <div class="warn-sign"><img src="./../../../static/img/exclamation.svg" alt=""></div>
                            <p>BETA, INVITE ONLY.</p>
                            <p>Contact Support for Approval</p>
                        </div>
                    </div>
                    <label class="row-space-top-2 row-space-3 block" :class='user.forensic_watermarking != 1 ? "blurred" : ""'>
                        <el-switch active-color="#0AD688" @click='changeForensicWatermarking()'
                                   v-model="video.forensic_watermarking" class="row-space-right-1"></el-switch>
                        Enable Forensic Watermarking
                    </label>
                    <label for="" class="session_title" :class='user.forensic_watermarking != 1 ? "blurred" : ""'>Sessions watermarked</label>
                    <div class="session_count" :class='user.forensic_watermarking != 1 ? "blurred" : ""'>
                        <span class="sum-number" v-if="video.forensic_sessions_count !== null">{{ video.forensic_sessions_count }}</span>
                        <span class="sum-number" v-else>0</span>
                    </div>
-->
                    <label class="label_with_bg" for="">DYNAMIC VISUAL WATERMARKING
                        <span v-if="video.visual_watermarking" class="cs_status active">ACTIVE</span>
                        <span v-else class="cs_status">DISABLED</span>
                    </label>

                    <p class="row-space-top-1">Embed real-time, personalized & visible watermarks on your videos to
                        deter
                        viewers from unauthorized distibution.</p>

                    <label class="row-space-top-2 row-space-3 block">
                        <el-switch active-color="#0AD688" @change="changeVisualWatermarking()"
                                   v-model="video.visual_watermarking" class="row-space-right-1"></el-switch>
                        Enable Visual Watermarking
                    </label>
                    <div v-if="video.visual_watermarking">
                        <label for="" class="session_title">Embedded data</label>
                        <div class="embed-data-container visual-watermark-option">
                            <div class="watermark-list">
                                <div v-if="video.player_options.visual_watermark_ip">
                                    <span>IP Address</span>
                                </div>
                                <div v-if="video.player_options.visual_watermark_timestamp">
                                    <span>Timestamp</span>
                                </div>
                                <div v-if="video.player_options.visual_watermark_email">
                                    <span>Deter Text</span>
                                </div>
                                <div v-if="video.player_options.visual_watermark_name">
                                    <span>Full name(injected)</span>
                                </div>
                                <div v-if="video.player_options.geo_location">
                                    <span>Geo Location</span>
                                </div>
                            </div>
                            <div class="text-right" style="font-size: 18px;color: #3da5d8;cursor: pointer">
                                <span @click="updateVisualWatermarkContent">Edit data</span>
                            </div>


                        </div>
                    </div>

                    <label for="" class="session_title">Sessions watermarked</label>
                    <div class="session_count">
                        <span class="sum-number" v-if="video.visual_sessions_count !== null">{{ video.visual_sessions_count }}</span>
                        <span class="sum-number" v-else>0</span>
                    </div>
                </div>

            </div>
            <div class="upload-restriction-dialog-5"
                 v-if="blockedModules.includes('advanced_content_security')">
                <div class="warn-sign"><img src="./../../../static/img/exclamation.svg" alt=""></div>
                <p>Your current plan does not include Content Security.</p>
                <p>
                    If you create premium video content, we provide
                    you with real-time and aggressive anti-piracy tools
                    to protect your business from piracy, illegal downloads,
                    copyright information theft and loss of revenue.
                </p>
                <el-button round class="restriction_dialog_btn" type="success" @click="showUpgradeOptions"> See Upgrade
                    Options
                </el-button>
            </div>
        </div>
        <enable-visual-watermarking :video="video"
                                    :showEnableVisualWatermarkingPopup="showEnableVisualWatermarkingPopup"></enable-visual-watermarking>
        <enable-forensic-watermarking :video="video"
                                      :showEnableForensicWatermarkingPopup="showEnableForensicWatermarkingPopup"></enable-forensic-watermarking>
        <disable-watermarking :showDisableWatermarkingPopup="showDisableWatermarkingPopup"
                              :disableType="disableType"></disable-watermarking>
        <schedule-publishing
                :video="video"
                v-show="activeLevel2 == 'scheduled_publishing'"
                :packRestrict='user.current_plan && user.current_plan.stripe_plan == "free" || user.current_plan && user.current_plan.stripe_plan == "starter-monthly"'></schedule-publishing>

        <pixel-retargeting
                :video="video"
                v-show="activeLevel2 == 'pixel_retargeting'"
                :restricted='blockedModules.includes("pixel_retargeting")'
                @openUpgrade='showUpgradeOptions'>
        </pixel-retargeting>

    </div>


</template>
<style lang="less" src="../../assets/less/custom/fonts.less"></style>
<style lang="less">
    .error-msg{
        color: red !important;
    }
.funneldetailDilogue{
    .el-dialog{
        width: 70% !important;
    }

}
    .add-edit-video-funnel {
        .dynamic-video-funnel {
            width: 50%;
        }

        .video-config-url {
            display: flex;

            .title {
                margin-right: 10px;
            }
        }

        .video-config-interaction {
            background: #2fa2da21;
            padding: 10px;
            background: #2fa2da21;
            padding: 10px;

            .during-video-interaction {

                display: flex;

                .inner-during-video-interaction {
                    flex-wrap: wrap;
                    flex: 0 0 33.33%;
                }

            }

            .text-blue {
                color: #33abd8 !important;
            }
        }

        .video-config-head {
            display: flex;

            .inner-video-config-head {
                display: flex;
                flex-wrap: wrap;
                flex: 0 0 33.33%;
            }

            .inner-video-config-head {
                .title {
                    margin-right: 10px;
                }

                .el-input {
                    display: contents;
                }

                &.card-text .el-input .el-input__inner {
                    height: 30px;
                    width: 100px;
                }

                &.text-color .el-input .el-input__inner {
                    height: 30px;
                    width: 50px;
                }

                &.background-color .el-input .el-input__inner {
                    height: 30px;
                    width: 50px;
                }

                .colorpicker__ifield .colorpicker__colorvalue {
                    width: 7rem;
                }

                .colorpicker {
                    width: 100%;
                    position: relative;
                    height: 50px;
                    max-height: 50px;
                    overflow: visible;

                    .colorpicker__hszone {
                        z-index: 1;
                    }
                }
            }
        }
    }

    .dynamic-video-funnel {

        .funnel-videos {
            padding: 10px;
            background: #21455e;
            border-radius: 5px;
            margin-bottom: 10px;
            color: #fff;
            cursor: pointer;
            opacity: 0.7;

            .text-white {
                color: #fff !important;
            }
        }

        .add_new_child_logo {
            background: #ebf9fa;
            padding: 12px;
            border-radius: 5px;
            color: #33abd8;
            text-align: center;
            cursor: pointer;
        }


    }

    .player-skin-thumb {
        cursor: pointer;
    }

    .player-skin-thumb.active {
        border: 2px solid #45dab7;
    }

    .video-behaviour-options {
        .sticky-pause-input .el-input__inner {
            width: 100% !important;
        }

        .inner-behaviour-option {
            margin-left: 55px;
            display: inline-flex;

            .el-input__inner {
                height: 30px;
                width: 78px;
                color: #a2bbd4 !important;
            }

            span {
                line-height: 29px;
                margin: 0 5px;
                color: #a2bbd4 !important;
            }

            .live-icon-position {
                .el-input__inner {
                    width: 160px !important;
                    color: #a2bbd4 !important;
                }

                .el-input__icon {
                    right: 15px;
                }
            }
        }

    }

    .custom_logo_options input[type="file"] {
        display: none !important;
    }

    .custom_logo_options .el-upload-dragger {
        background-color: transparent;
        border: none;
        border-radius: 0;
        width: auto;
        height: auto;
        text-align: left;
        cursor: pointer;
        position: relative;
        overflow: unset;
    }

    .branding-logo-detail {
        .branding-logo-position {
            display: flex;
            margin-top: 25px;

            .positions {
                flex-wrap: wrap;
                flex: 0 0 50%;

                input {
                    border: none;
                    width: 140px;
                }
            }

            & .title {
                line-height: 40px;
                color: #909090;
            }

        }

        .add_new_branding_logo {
            background: #ebf9fa;
            padding: 12px;
            border-radius: 5px;
            color: #33abd8;
            text-align: center;
            cursor: pointer;
        }

        .list-all-branding-logo {
            .brand-logo {
                height: auto;
                width: auto;
                margin: 10px;
                position: relative;
                cursor: pointer;
                display: inline-flex;

                img {
                    height: 50px;
                    width: auto;
                    border-radius: 5px;
                }

                .el-dropdown-link {
                    text-align: center;
                    width: 20px;
                    height: 20px;
                    /*border: 2px solid #f8f8f9;*/
                    border-radius: 101px;
                    background: url('../../assets/img/3 - dot.svg') center center no-repeat !important;
                    background-size: 15px !important;
                    display: inline-block;
                    /*margin-top: 5px;*/
                    cursor: pointer;
                }

                .logo-options {
                    position: absolute;
                    top: 0;
                    right: 0;

                }

            }

            .brand-logo img.active {

                border: 2px solid #45dab7;
            }
        }

        .upload-branding-logo {
            .el-upload-dragger {
                border: none !important;
                height: auto;

            }

            .el-upload.el-upload--text .el-upload__input {
                display: none !important;
            }
        }
    }

    .visual-watermark-option {

        .watermark-list {
            /*display: flex;*/

            span {
                padding: 10px;
                background: #f6f6f6;
                border-radius: 20px;
                margin-right: 10px;
                white-space: nowrap;
            }
        }

        div {
            margin: 15px 0;
            display: inline-block;
        }
    }

    .el-dropdown-menu.video-settings-dropdown {
        /*max-height: 300px;*/
        /*overflow-y: scroll;*/
    }


    .download-edited-file {
        span b {
            color: #33abd8;
            cursor: pointer;
        }
    }

    .addCaptionBtn {
        position: absolute;
        right: 0;
        height: 100%;
        top: 0;
        padding: 20px;
        background: #33abd8;
        border-radius: 0 4px 4px 0;
        color: #fff;
        cursor: pointer;
    }

    .add_new_subTitleCaption {
        margin: 15px 0;
        position: relative;

        .el-textarea__inner {
            resize: none;
            height: 60px;
            border: 1px solid #81d3ea;
        }
    }

    .list_srt_captions {
        display: flex;
        padding: 5px 0px 5px 5px;
        background: #efefef82;
        border-radius: 5px;
        margin-bottom: 8px;
        flex-wrap: wrap;

        .captionText {
            flex: 0 0 66%;

            .el-textarea__inner {
                height: 54px;
                font-size: 13px;
                margin: 2px 5px;
                line-height: 15px;
                padding: 5px;
                resize: none;
            }
        }

        .captionStartEndTime {
            flex: 0 0 25%;

            .el-input__inner {
                height: 25px;
                font-size: 13px;
                margin: 2px 0;
                border-radius: 3px;

            }
        }

        .captionActionIcons {
            flex: 0 0 8%;
            display: none;

            i {
                margin-left: 10px;
                font-size: 10px;
                color: #2d3c4a;
                cursor: pointer;
            }

        }


    }

    .list_srt_captions:hover {
        .captionActionIcons {
            display: block;
        }
    }

    .video-language:hover {
        background: #ebf9fa;

        .detail_drop_down {
            color: #3db3dc;
        }

    }

    .detail_drop_down {
        position: absolute;
        right: 10px;
        top: 12px;
        cursor: pointer;
    }

    .add_new_sub_language {
        .dropdown-container {
            width: 100%;
        }
    }

    .video-language {
        padding: 8px;
        background: #cccccc38;
        margin: 18px 0;
        border-radius: 5px;
        position: relative;
    }

    .generate_notice {
        padding: 10px;
        background: #21455d12;
        border-radius: 25px;
        color: var(--color-red) !important;
        background: #f76e2c14;
        margin: 15px 0;
    }

    .list_uploaded_file {
        display: flex;
        /* flex: 0 0 20%; */
        font-size: 30px;

        .file_size {
            position: absolute;
            right: 20%;
        }

        .remove_file {
            position: absolute;
            right: 0;
        }

        .file_name {
            width: 100%;
        }

        .file_name label {
            width: 60%;
            text-overflow: ellipsis !important;
            overflow: hidden;
            white-space: nowrap;
            max-width: 60%;
            line-height: 50px;

        }
    }

    .list_uploaded_file > div {
        padding: 5px;
    }

    .upload_srt_file.error {
        .inner {
            color: var(--color-red);
            background: #f76e2c14;
        }
    }

    .upload_srt_file {
        width: 100%;
        display: inline-block;
        position: relative;
        /*padding: 15px;*/
        cursor: pointer;

        &.full-width {
            display: block;
            width: 100%;
        }

        .el-upload {
            display: block;
        }

        .el-upload-dragger {
            width: 100% !important;
            border: none !important;
            height: auto;
        }

        .el-upload__input {
            display: none !important;
        }

        .inner {
            height: 90px;
            background: #F4FDFF;
            color: #00ACDC;
            border-radius: 3px;
            -webkit-border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 3px;
            -webkit-border-radius: 3px;
            font-size: 14px;
        }
    }

    .upload_subtitles_file .el-select.custom_select {
        width: 100%;
    }

    .translation_notice {
        padding: 10px;
        background: #21455d12;
        border-radius: 25px;
    }

    .add_new_chapter {
        background: #ebf9fa;
        padding: 12px;
        border-radius: 5px;
        color: #33abd8;
        text-align: center;
        cursor: pointer;
    }

    .add_new_subtitle_language {
        display: block;
        background: #ebf9fa;
        padding: 12px;
        border-radius: 5px;
        color: #33abd8;
        text-align: center;
        cursor: pointer;
    }

    .new-chapter-input .el-input {
        margin: 10px;
    }

    .new-chapter-input {
        background: #efefef82;
        margin-bottom: 10px;
        position: relative;

    }

    .new-chapter-input .chapter-time-input {
        width: 30%;
    }

    .new-chapter-input .chapter-title-input {
        width: 50%;
    }

    .chapter-remove-btn {
        position: absolute;
        top: 18px;
        right: 15px;
        display: none;
        cursor: pointer;
        font-size: 11px;
        color: #828282;
    }

    .new-chapter-input:hover {
        background: #ebf9fa;
    }

    .new-chapter-input:hover .chapter-remove-btn {
        display: block;
    }

    .custom_switchers.el-switch .el-switch__core:after {
        font-family: element-icons !important;
        color: #ff5f5f;
        content: "\E61C";
        display: flex;
        align-items: center;
        /* margin-top: 0px; */
        padding: 0.5px;
        padding-top: 0.7px;
        transform: rotate(45deg);
    }

    .custom_switchers.el-switch.is-checked .el-switch__core:after {
        color: #67c23a;
        font-family: element-icons !important;
        content: "\E600";
        display: flex;
        align-items: center;
        /* margin-top: 0px; */
        padding: 0.5px;
        padding-top: 0.7px;
        transform: none
    }

    .switcher_custom_div .el-icon-close::before {
        content: ''
    }

    .switcher_custom_div .el-icon-check::before {
        content: ''
    }

    .w-100 {
        width: 100%;
    }

    .content_security {

    }

    .content_security .label_with_bg {
        margin-top: 15px;
        width: 100%;
    }

    .content_security .label_with_bg span {
        float: right;
    }

    .content_security .session_count {
        text-align: center;
        margin: 8px 0;
    }

    .content_security .session_count .sum-number {
        background-color: #cccccc2b;
        font-size: 24px;
        padding: 8px 15px;
        margin-bottom: 5px;
        margin-top: 5px;
        border-radius: 5px;
    }

    .content_security .session_title {
        margin-top: 20px;
    }

    .content_security .cs_status {
        background-color: #cccccc2b;
        font-size: 12px;
        color: #616161;
        padding: 5px 8px;
        border-radius: 20px;
        line-height: 12px;
    }

    .content_security .cs_status.active {
        background-color: #44d6881a;
        color: #0ad688;
    }

    .el-button--primary, .el-button--danger {
        border-radius: 30px;
    }


    .privacy-options {
        position: relative;
        font-family: "Helvetica Neue";
    }

    .privacy-options .row-space-top-1 {
        line-height: 1.7 !important;
    }

    .privacy-options span {
        line-height: 2.5;
    }

    .privacy-options .new-domain-input span {
        line-height: 0 !important;
    }

    .divide {
        width: 100%;
        height: 1px;
        background: #EBF9FA;
        margin: 23px 0;
    }

    .download-text {
        line-height: 1.7 !important;
    }

    .download-toggle {
        position: relative;
        top: 4px;
    }

    .allow-download-text {
        margin-left: 15px;
    }

    .relative-margin {
        position: relative;
        top: 15px;
    }

    .upload-restriction-dialog-5 {
        font-size: 14px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 200;
    }

    .warn-sign {
        position: relative;
        width: 60px;
        height: 60px;
        text-align: center;
        margin-bottom: 15px;

        img {
            max-width: 100%;
            object-fit: cover;
        }
    }

    .restriction_dialog_btn {
        border-radius: 19px;
        padding: 8px 25px;
        background-color: #F27500;
        border-color: #F27500;
        color: white;
        margin-top: 15px;
    }

    .language-dropdown {
        max-height: 300px;
        overflow: inherit;
    }
</style>

<style lang="less" src="../../assets/less/custom/editor.less"></style>

<script>

    import EnableVisualWatermarking from "../Project/ProjectDialogs/enableVisualWatermarkingDIalog";
    import DisableWatermarking from "../Project/ProjectDialogs/disableWatermarkingDialog";
    import EnableForensicWatermarking from "../Project/ProjectDialogs/enableForensicWatermarkingDIalog";
    import draggable from "vuedraggable";
    // import Toggle
    //     from '../Project/ChildPages/ProjectExperimentationPages/NewVideoExperiment/templates/PerformanceGoalsToggle';
    import AddIcon from './AddIcon';

    import AppHelper from "../../utils/AppHelper";


    import EditorThumbnailOptions from '../Editor/EditorThumbnailOptions';
    import ColorPicker from './ColorPicker.vue';
    import BackgroundColorPicker from './BackgroundColorPicker.vue';
    import FunnelColorPicker from './FunnelColorPicker.vue';
    import EditorDomains from './Items/EditorDomains.vue';


    import SchedulePublishing from "./SchedulePublishing";
    import PixelRetargeting from "./PixelRetargeting";
    import VueClipboard from 'vue-clipboard2';
    import FunnelIntegrationModal from "./../Project/FunnelIntegrationModal.vue";

    export default {
        props: [
            'video',
            'user',
            'activeLevel2',
            'currentTime',
            'blockedModules',
            // 'placeholder',
        ],

        data() {
            return {
                showEnableVisualWatermarkingPopup: false,
                showDisableWatermarkingPopup: false,
                showEnableForensicWatermarkingPopup: false,
                disableType: 'Forensic Watermarking',
                items: [],
                enabled: true,
                dragging: false,
                uploadSubtitlesFile: false,
                uploadTranslatedSubtitlesFile: false,
                generateSubtitlesFile: false,
                subtitlesTranslation: false,
                selLanguage: this.video.video_subtitles !== null ? this.video.video_subtitles.language : '',
                placeholder: 'Select Language',
                endpoint: window.app.backendUrl + 'api/store_file',
                logoendpoint: window.app.backendUrl + 'api/brand_logo/' + this.user.id,
                logoreplaceendpoint: window.app.backendUrl + 'api/replace/brand_logo/',
                upload_in_progress: false,
                fileList: '',
                invalidFileFormat: false,
                showFile: false,
                showUploadArea: true,
                uploadedFiles: [],
                editSrtFile: false,
                SrtCaptions: [],
                subText: '',
                SrtFileToEdit: [],
                VisualWatermarkContent: false,
                generateBtnText: 'Generate Subtitles',
                importBtnText: 'Continue',
                translateBtnText: 'Translate',
                translateBtnDisabled: false,
                fileType: false,
                disabled: false,
                advanced_content_security: true,
                editSubs: true,
                loading: false,
                pixel_retargeting: true,
                showStageWarning: {
                    head: 'Your current plan does not include stage.',
                    body: 'Please upgrade to our premium plans to access this feature.',
                    show: false,
                },
                showRestrictionPopover: false,
                laguageError: false,
                fileError: false,
                invalidLogoFormat: false,
                logotoEdit: null,
                message: '',
                editChildVideo: false,
                branchVideos: [],
                videoToAddFunnel: {
                    id: null,
                    parent_id: null,
                    title: '',
                    power_player_type: 'YOUTUBE',
                    path: '',
                    player_options: {
                        enable_video_funnel: false,
                        video_funnel_time: '',
                        funnel_text: '',
                        video_card_text: '',
                        video_text_color: '#fff',
                        video_background_color: '#000',
                    },
                },
                disabledBtn: false,
                validateVideo: {
                    title: false,
                    card_text: false,
                    power_player_type: false,
                    path: false,
                    message: ''
                },
                loadFunnelVideoAction: '',
                videoLoaded: true,
                funnel_time: '00:00:00',
                // selLanguage: video.video_subtitles.language,
                // error: false

            };
        },

        components: {
            EditorThumbnailOptions,
            ColorPicker,
            EditorDomains,
            EnableVisualWatermarking: EnableVisualWatermarking,
            DisableWatermarking: DisableWatermarking,
            draggable,
            EnableForensicWatermarking: EnableForensicWatermarking,
            // Toggle: Toggle,
            AddIcon: AddIcon,
            SchedulePublishing,
            PixelRetargeting,
            VueClipboard,
            AppHelper,
            BackgroundColorPicker,
            FunnelColorPicker,
            FunnelIntegrationModal,


        },
        created() {
            this.message = "<script src='" + process.env.VUE_APP_BACKEND_URL + process.env.VUE_APP_sticky_BaseScript + "'>\<\/script>"
            console.log(this.video);
            if (this.video.video_subtitles !== null && this.video.video_subtitles.sub_status !== 'completed') {


                if (this.video.video_subtitles.sub_status === 'in_progress') {
                    this.disabled = true;
                    this.generateBtnText = 'Generating..';

                    var $this = this;
                    var timeInterval = setInterval(function () {
                        let p_data = {
                            data: {
                                job_id: $this.video.video_subtitles.job_id,
                            },
                            url: "/checkStatus"
                        };
                        $this.$store.dispatch("post", {...p_data})
                            .then(response => {

                                if (response.data.status == "generated") {
                                    $this.disabled = false;
                                    $this.generateBtnText = 'Completed'
                                    clearInterval(timeInterval)
                                    $this.generateSubtitlesFile = false
                                    $this.video.player_options.subtitle_control = 'true';
                                    $this.video.video_subtitles = response.data.subtitle;

                                    $this.generateBtnText = 'Generate Subtitles'

                                }
                                if (response.data.status == "failed") {
                                    $this.disabled = false;
                                    $this.generateSubtitlesFile = false
                                    clearInterval(timeInterval)
                                    $this.generateBtnText = 'Generate Subtitles'
                                }

                            })
                            .catch(error => {

                            });
                    }, 5000);

                } else {
                    this.disabled = false;
                    this.generateBtnText = 'Generate Subtitles';
                }
            }
            // console.log(this.video.child_videos, 'video');
            this.branchVideos = this.video.child_videos
            this.video.player_options.video_funnel_time = AppHelper.convertSecondsToTime(this.video.player_options.video_funnel_time);

        },
        methods: {
            changeFunnelTime(e){
                this.funnel_time = e;
                this.video.player_options.video_funnel_time = AppHelper.convertTimeToSeconds(e);
                console.log('ppp--')
            },
            getChildVideos() {
                this.axios.post('/powerPlayer/child/' + this.video.id, this.videoToAddFunnel)
                    .then(response => {
                        this.video.child_videos = response.data.videos;

                    });
            },
            actionType(type = '', $event) {

                console.log(type, $event, '=-=-=-');
                if ($event) {
                    this.loadFunnelVideoAction = type
                } else {
                    this.loadFunnelVideoAction = ''
                }

            },
            enableVideoFunnel($event) {

                if ($event) {
                    this.videoToAddFunnel.player_options.enable_video_funnel = true;
                } else {
                    this.videoToAddFunnel.player_options.enable_video_funnel = false;
                }
                console.log($event);
            },
            storeBranchVideo() {
                console.log('test test');
                this.validateVideo.title = false;
                this.validateVideo.message = '';
                this.validateVideo.path = false;
                this.validateVideo.card_text = false;

                if (this.videoToAddFunnel.player_options.video_card_text === '') {
                    this.validateVideo.card_text = true;
                    this.validateVideo.message = 'Please enter card text';
                    return false;
                } else {
                    this.validateVideo.title = false;
                    this.validateVideo.message = '';
                }


                if (this.videoToAddFunnel.title == '') {
                    this.validateVideo.title = true;
                    this.validateVideo.message = 'Please enter video title';
                    return false;
                } else {
                    this.validateVideo.title = false;
                    this.validateVideo.message = '';
                }
                if (this.videoToAddFunnel.path == '') {
                    this.validateVideo.path = true;
                    this.validateVideo.message = 'Please enter source URL';
                    return false;
                } else {


                    if (this.videoToAddFunnel.power_player_type == 'YOUTUBE') {
                        this.validateVideo.path = true;
                        this.validateVideo.message = 'Please enter valid source URL';
                        if (!this.videoToAddFunnel.path.includes('www.youtube.com')) {
                            return false;
                        }
                    }
                    if (this.videoToAddFunnel.power_player_type == 'WISTIA') {
                        this.validateVideo.path = true;
                        this.validateVideo.message = 'Please enter valid source URL';
                        if (!this.videoToAddFunnel.path.includes('wistia.com')) {
                            return false;
                        }
                    }
                    if (this.videoToAddFunnel.power_player_type == 'VIMEO') {
                        this.validateVideo.path = true;
                        this.validateVideo.message = 'Please enter valid source URL';
                        if (!this.videoToAddFunnel.path.includes('vimeo.com')) {
                            return false;
                        }

                    }
                    if (this.videoToAddFunnel.power_player_type == 'MP4') {

                        var re = /(?:\.([^.]+))?$/;

                        var ext = re.exec(this.videoToAddFunnel.path)[1];

                        if (typeof ext == 'undefined' || ext !== "mp4") {
                            this.validateVideo.path = true;
                            this.validateVideo.message = 'Please enter valid source URL';
                            if (!this.videoToAddFunnel.path.includes('vimeo.com')) {
                                return false;
                            }
                        }
                    }
                    if (this.videoToAddFunnel.power_player_type == 'M3U8') {

                        var re = /(?:\.([^.]+))?$/;

                        var ext = re.exec(this.videoToAddFunnel.path)[1];

                        if (typeof ext == 'undefined' || ext !== "m3u8") {
                            this.validateVideo.path = true;
                            this.validateVideo.message = 'Please enter valid source URL';
                            if (!this.videoToAddFunnel.path.includes('vimeo.com')) {
                                return false;
                            }
                        }
                    }


                    this.validateVideo.path = false;
                    this.validateVideo.message = '';
                }

                console.log(this.videoToAddFunnel, 'this.videoToAddFunnel');


                this.disabledBtn = true;
                this.axios.post('/powerPlayerVideos/storeBranchVideo', this.videoToAddFunnel)
                    .then(response => {
                        this.loadFunnelVideoAction = '';
                        this.disabledBtn = false;
                        this.videoToAddFunnel = {
                            id: null,
                            parent_id: null,
                            title: '',
                            power_player_type: 'YOUTUBE',
                            path: '',
                            player_options: {
                                enable_video_funnel: false,
                                video_funnel_time: '',
                                funnel_text: '',
                                video_card_text: '',
                                video_text_color: '#fff',
                                video_background_color: '#000',
                            },
                        };
                        this.editChildVideo = false;
                        this.branchVideos.forEach(function (ele, index) {

                            if (response.data.video.title == ele.title) {
                                ele = response.data.video
                            }

                        });

                        this.getChildVideos();

                    });
            },
            configureFunnelVideo(video, parent_id) {
                this.editChildVideo = true;


                // this.videoToAddFunnel.parent_id = parent_id;

                if (video.id == null) {
                    this.videoToAddFunnel = {
                        id: null,
                        parent_id: parent_id,
                        title: video.title,
                        power_player_type: 'YOUTUBE',
                        path: '',
                        player_options: {
                            enable_video_funnel: false,
                            video_funnel_time: '',
                            funnel_text: '',
                            video_card_text: '',
                            video_text_color: '#fff',
                            video_background_color: '#000',
                        },
                    };
                } else {


                    let p_data = {

                        url: "/powerPlayer/show/" + video.id
                    };
                    this.videoLoaded = false;
                    var $this = this;
                    this.$store.dispatch("post", {...p_data})
                        .then(response => {
                            this.videoToAddFunnel = response.data.video
                            this.videoToAddFunnel.parent_id = parent_id;
                            this.videoToAddFunnel.player_options.video_funnel_time = AppHelper.convertSecondsToTime(this.videoToAddFunnel.player_options.video_funnel_time);
                            this.videoToAddFunnel.player_options.enable_video_funnel = response.data.video.player_options.enable_video_funnel === "true";
                            this.videoToAddFunnel.player_options.interaction_during_email_capture = response.data.video.player_options.interaction_during_email_capture === "true";
                            this.videoToAddFunnel.player_options.interaction_during_active = response.data.video.player_options.interaction_during_active === "true";
                            this.videoToAddFunnel.player_options.video_background_color = response.data.video.player_options.video_background_color;
                            this.videoLoaded = true
                            // console.log(this.videoToAddFunnel.player_options.enable_video_funnel, 'parent_id');
                        })
                        .catch(error => {

                        });


                    // this.videoToAddFunnel = video
                }
                // this.videoToAddFunnel.power_player_type = video.name;

            },
            addNewBranchVideo() {
                if (this.video.child_videos.length < 5) {
                    this.video.child_videos.push({
                        title: 'video ' + (this.video.child_videos.length + 1),
                        id: null
                    })
                }
                // console.log(this.branchVideos);
            },
            addBranchVideo() {
                if (this.videoToAddFunnel.child_videos.length < 5) {
                    this.videoToAddFunnel.child_videos.push({
                        title: 'video ' + (this.videoToAddFunnel.child_videos.length + 1),
                        id: null
                    })
                }
            },

            enableAutoplay($event) {

                if ($event) {

                    this.video.player_options.volume_control = false
                }
            },
            pausePlayer(val, type) {

                if (type == "sticky") {
                    if (val) {
                        this.video.player_options.smart_pausing = false;
                    }
                }
                if (type == "pause") {
                    if (val) {
                        this.video.player_options.sticky_player = false;
                    }
                }

                console.log(val, type);
            },
            onCopy: function (e) {
                window.vEvent.fire("settings-common-success", {
                    title: 'Copied',
                    message: e.text,
                    close: false
                });
            },
            onError: function (e) {
                window.vEvent.fire("settings-common-success", {
                    title: 'Copied',
                    message: 'Failed to copy texts',
                    close: false
                });
            },
            PlayerSkin(type) {
                this.video.player_options.player_skin = type
            },
            logoToEdit(logo) {
                console.log('logo');
                this.logotoEdit = logo;
            },
            handleLogoCommand(command, logo) {
                console.log(command);
                if (command == 'replacelogo') {

                }


                if (command == 'deleteLogo') {

                    let p_data = {

                        url: "/delete_brand_logo/" + this.logotoEdit.id
                    };
                    var $this = this;
                    this.$store.dispatch("post", {...p_data})
                        .then(response => {
                            var data = [];
                            $this.user.branding_logos.forEach(function (ele, i) {
                                if (ele.id !== $this.logotoEdit.id) {
                                    data.push(ele)
                                }
                            })
                            $this.user.branding_logos = data
                            $this.logotoEdit = null;
                        })
                        .catch(error => {

                        });

                }

            },
            handleBrandLogoUploadProgress(ev, file, rawFile) {
                // this.upload_in_progress = true;
                // if (ev.total > 0) {
                //     let percentage = parseInt(ev.loaded / ev.total * 100) || 0;
                //     if (percentage === 100) {
                //         this.$Progress.finish();
                //         this.upload_in_progress = false;
                //     } else {
                //         this.$Progress.update(percentage);
                //     }
                // }
            },

            handleBeforeBrandLogoUpload(file) {


                var allowedType = ["image/jpeg", 'image/png'];

                if (allowedType.indexOf(file.type) === -1) {
                    this.invalidLogoFormat = true;
                    return false;
                } else {
                    this.invalidLogoFormat = false;
                    return true;
                }
            },
            handleBrandLogoUploadSuccess(res, file) {

                //console.log('ppppopo', res, this.user)
                if(this.user.branding_logos === null) {
                    this.user.branding_logos = []
                }
                this.user.branding_logos.push(res.logo)

                console.log(res, file, this.user, 'user');

            },
            handleBrandLogoReplaceSuccess(res, file) {


                this.user.branding_logos.forEach(function (ele, i) {
                    if (ele.id == res.logo.id) {
                        ele.logo = res.logo.logo;
                    }
                })

                // this.user.branding_logos.push(res.logo)

                console.log(res, file, this.user, 'user');

            },
            bytesToSize(bytes) {
                var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                if (bytes == 0) return '0 Byte';
                var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
                return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
            },
            removeSubtitleInitialisation() {
                document.querySelector(".vjs-captions-button .vjs-menu-item:not(.vjs-texttrack-settings)").click();
            },
            editVideoSrtFile(id) {

            },

            removeVideoSrtFile(id) {
                var $this = this;
                let post_data = {
                    data: {
                        id: id

                    },
                    url: "/reomveVideoSubtitle"
                };

                $this.$store.dispatch("post", {...post_data})
                    .then(response => {
                        $this.video.video_subtitles = null;
                    })
                    .catch(error => {

                    });

            },
            updateVisualWatermarkContent() {
                this.VisualWatermarkContent = true;
            },
            generateSrtFromVideo() {
                var $this = this;
                var lang = '';
                this.$store.state.languages.forEach((value, index) => {
                    if (value.code === this.selLanguage) {
                        lang = value.name;
                    }
                });

                let post_data = {
                    data: {
                        vid: this.video.id,
                        language: this.selLanguage,
                        lang_name: lang,
                    },
                    url: "/generateSrtFromVideo"
                };
                this.$store.dispatch("post", {...post_data})
                    .then(response => {
                        $this.generateBtnText = 'Generating..';
                        $this.disabled = true;

                        console.log(response, response.data, '==-=-=-=-=-=-=-');

                        if (response.data.status) {
                            var timeInterval = setInterval(function () {
                                let p_data = {
                                    data: {
                                        job_id: response.data.job_id,
                                    },
                                    url: "/checkStatus"
                                };
                                $this.$store.dispatch("post", {...p_data})
                                    .then(response => {

                                        if (response.data.status == "generated") {
                                            $this.disabled = false;
                                            $this.generateBtnText = 'Completed'
                                            clearInterval(timeInterval)
                                            $this.generateSubtitlesFile = false
                                            $this.video.player_options.subtitle_control = true;
                                            $this.video.video_subtitles = response.data.subtitle;

                                            $this.generateBtnText = 'Generate Subtitles'

                                        }
                                        if (response.data.status == "failed") {
                                            $this.disabled = false;
                                            $this.generateSubtitlesFile = false
                                            clearInterval(timeInterval)
                                            $this.generateBtnText = 'Generate Subtitles'
                                        }

                                    })
                                    .catch(error => {

                                    });
                            }, 5000);

                        }

                    })
                    .catch(error => {

                    });

                setTimeout(function () {
                    $this.generateSubtitlesFile = false

                }, 5000);
            },

            generateAndDownloadSrt(url = null) {

                if (url === null) {
                    var data = {
                        id: this.SrtFileToEdit.id,
                        captions: this.SrtCaptions,
                        type: this.fileType ? 'original' : 'translated',

                    }
                } else {
                    var data = {
                        url: url,
                    }
                }

                let post_data = {
                    data: data,
                    url: "/generateAndDownloadSrt"
                };
                this.$store.dispatch("post", {...post_data})
                    .then(response => {
                        window.location.href = response.data.url;
                        console.log(response.data)

                    })
                    .catch(error => {

                    });
            },
            uploadTranslatedSrtFileToEdit() {

                console.log(this.SrtFileToEdit, 'SrtFileToEdit');
                let post_data = {
                    data: {
                        id: this.SrtFileToEdit.id,
                        language: this.SrtFileToEdit.language,
                        file: this.fileList
                    },
                    url: "/uploadTranslatedSrtFileToEdit"
                };
                this.$store.dispatch("post", {...post_data})
                    .then(response => {
                        this.SrtCaptions = response.data.captions;
                        this.uploadTranslatedSubtitlesFile = false;
                    })
                    .catch(error => {

                    });

            },
            AddRemoveSubtitleCaption(captionIndex, type) {
                const blockedModules = this.$props.blockedModules;
                if (blockedModules.includes("subtitles")) return false;
                if (type == 'remove') {
                    var temp = [];
                    this.SrtCaptions.forEach((value, index) => {
                        if (index !== captionIndex) {
                            temp[temp.length] = value;
                        }
                    });
                    this.SrtCaptions = temp;
                }
                if (type == 'add') {

                    this.SrtCaptions.splice(captionIndex + 1, 0, {
                        start: '00:00:00',
                        end: '00:00:00',
                        text: null,
                    });

                }
                if (type == 'add_first') {
                    // console.log(subText);

                    this.SrtCaptions.splice(captionIndex, 0, {
                        start: '00:00:00',
                        end: '00:00:00',
                        text: this.subText,
                    });
                    this.subText = '';

                }

                this.video.translated_srt_caption['captions'] = this.SrtCaptions;
            },
            editTranslatedFile(id, type) {

                let post_data = {
                    data: {
                        id: id,
                        type: type

                    },
                    url: "/editTranslatedSubtitle"
                };

                this.$store.dispatch("post", {...post_data})
                    .then(response => {
                        this.SrtCaptions = response.data.captions;

                        this.SrtFileToEdit = response.data.data;
                        if (response.data.type == "original") {
                            this.fileType = true;
                        }
                        this.editSrtFile = true;

                        this.video.translated_srt_caption['captions'] = this.SrtCaptions;
                        this.video.translated_srt_caption['id'] = id;
                        this.video.translated_srt_caption['type'] = response.data.type;
                        // console.log(this.video, this.video.translated_srt_caption, 'kkkkjkjkj');

                    })

                    .catch(error => {

                    });

            },
            removeTranslatedFile(id) {


                var $this = this;
                this.$swal({
                    title: 'Are you sure?',
                    text: 'Deleted srt files are gone forever',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    cancelButtonText: 'Cancel'
                }).then(function () {

                    let post_data = {
                        data: {
                            id: id

                        },
                        url: "/reomveTranslatedSubtitle"
                    };

                    $this.$store.dispatch("post", {...post_data})
                        .then(response => {
                            var temp = [];
                            $this.video.video_subtitles.translated_subtitles.forEach((value, index) => {
                                if (value.id !== id) {
                                    temp[temp.length] = value;
                                }
                            });
                            $this.video.video_subtitles.translated_subtitles = temp;
                        })
                        .catch(error => {

                        });

                });

            },
            translateSrt() {
                var $this = this;
                $this.translateBtnDisabled = true;
                $this.translateBtnText = 'Generating..';
                var lang = '';
                this.$store.state.languages.forEach((value, index) => {
                    if (value.code === this.selLanguage) {
                        lang = value.name;
                    }
                });
                let post_data = {
                    data: {
                        language: this.selLanguage,
                        lang_name: lang,
                        subtitle_id: this.video.video_subtitles.id,

                    },
                    url: "/translate_srt_file"
                };

                this.$store.dispatch("post", {...post_data})
                    .then(response => {

                        if (!response.data.exist) {

                            $this.video.video_subtitles.translated_subtitles.push(response.data.data);
                        } else {
                            $this.$message({
                                showClose: true,
                                message: "Subtitle is already translated in this language",
                                type: "success"
                            });
                        }
                        $this.subtitlesTranslation = false
                        $this.translateBtnDisabled = false;
                        $this.translateBtnText = 'Traslate';

                    })
                    .catch(error => {

                    });

            },
            uploadSrtFile() {
                var $this = this;
                var lang_name = '';
                this.$store.state.languages.forEach((value, index) => {
                    if (value.code === this.selLanguage) {
                        lang_name = value.name;
                    }
                });

                if (this.selLanguage == '') {
                    this.laguageError = true
                    return false;
                } else {
                    this.laguageError = false
                }

                if (this.fileList == '' && !this.showFile) {
                    this.fileError = true
                    return false;
                } else {
                    this.fileError = false
                }

                this.importBtnText = 'Uploading..'
                this.disabled = true
                let post_data = {
                    data: {
                        language: this.selLanguage,
                        lang_name: lang_name,
                        file: this.fileList,
                        vid: this.video.id,
                    },
                    url: "/store_subtitle_detail"
                };
                this.$store.dispatch("post", {...post_data})
                    .then(response => {

                        $this.uploadSubtitlesFile = false;
                        $this.fileList = '';
                        $this.showFile = false;
                        $this.showUploadArea = true;
                        $this.uploadedFiles = []
                        this.importBtnText = 'Countinue';
                        this.disabled = false;
                        this.video.video_subtitles = response.data.subtitle;

                    })
                    .catch(error => {
                        $this.fileList = '';
                        $this.showFile = false;
                        this.importBtnText = 'Countinue';
                        this.disabled = false
                    });

            },
            handleImageUploadProgress(ev, file, rawFile) {
                this.upload_in_progress = true;
                if (ev.total > 0) {
                    let percentage = parseInt(ev.loaded / ev.total * 100) || 0;
                    if (percentage === 100) {
                        this.$Progress.finish();
                        this.upload_in_progress = false;
                    } else {
                        this.$Progress.update(percentage);
                    }
                }
            },
            handleBeforeImageUpload(file) {

                // const isLt2M = file.size / 1024 / 1024 < 8;
                // if (!isLt2M) {
                //     this.$message.error('Image picture size can not exceed 8MB!');
                //     return false;
                // }
                console.log(file, file.type, '===', allowedType);


                // var allowedType = ['text/vtt'];
                var allowedType = ["application/x-subrip", 'text/vtt'];
                // var valid = file.type.match(allowedType);
                // if (!valid) {


                if (file.name.substring(file.name.length - 4) === ".sbv" || file.name.substring(file.name.length - 4) === ".srt") {
                    document.getElementById('upload_srt_file').classList.remove('error')
                    this.invalidFileFormat = false;
                    this.$Progress.start();
                    return true;
                } else if (allowedType.indexOf(file.type) === -1) {
                    this.invalidFileFormat = true;
                    document.getElementById('upload_srt_file').classList.add('error')
                    return false;
                } else {
                    // this.$message.error('Image picture must be SRT format!');
                    document.getElementById('upload_srt_file').classList.remove('error')
                    this.invalidFileFormat = false;
                    this.$Progress.start();
                    return true;
                }
            },
            handleImageUploadSuccess(res, file) {

                this.upload_in_progress = false;

                if (res.filename !== '') {
                    this.showFile = true;
                    this.showUploadArea = false;
                    this.fileList = res.filename;

                    this.uploadedFiles.push({
                        name: file.name,
                        size: file.size,
                    })
                    // this.fileList.push(res.filename);
                }


            },
            removeSrt() {
                console.log('kjkjk');
                this.showFile = false;
                this.showUploadArea = true;
                this.uploadedFiles = []
            },
            showPrompt: function () {
                if (this.video.player_options.text_overlay) {
                    this.video.player_options.text_overlay = false;
                    this.$message({
                        message: "Please Disable 'Before Video' Interaction Settings!",
                        type: 'error'
                    });
                }
            },
            changeBrand: function (branding) {
                var $this = this;

                var data = {};
                if (branding) {
                    data.logo = this.user.settings.logo;
                    data.video_id = this.video.id;
                    this.axios({
                        method: 'post',
                        url: 'editor/branding-logo',
                        data: data
                    }).then(function (response) {
                        $this.video.player_options.branding_logo = response.data;
                    })["catch"](function (error) {

                    });
                }

            },
            handleImageLibraryOpen(model) {

                this.$emit('open-image-library', model)
            },
            updateDomainInfo(val) {
                this.video.player_options.whitelisted_domains = JSON.stringify(val);
            },
            changeVisualWatermarking() {
                const blockedModules = this.$props.blockedModules;

                if (blockedModules.includes('advanced_content_security')) return false;
                if (this.video.visual_watermarking) {
                    this.disableType = 'Forensic Watermarking';
                    this.showEnableVisualWatermarkingPopup = true;
                    this.video.visual_watermarking = false;
                } else {
                    this.disableType = 'Dynamic Visual Watermarking';
                    this.video.visual_watermarking = true;
                    this.showDisableWatermarkingPopup = true;
                }
            },
            changeForensicWatermarking() {
                const blockedModules = this.$props.blockedModules;
                if (blockedModules.includes('advanced_content_security')) return false;
                if (this.user.forensic_watermarking != 1) return false;
                // if (this.user.current_plan && this.user.current_plan.stripe_plan == 'free' || this.user.current_plan && this.user.current_plan.stripe_plan == 'starter-monthly') return false;
                if (this.video.forensic_watermarking) {
                    this.disableType = 'Dynamic Visual Watermarking';
                    this.video.forensic_watermarking = false;
                    this.showEnableForensicWatermarkingPopup = true;
                } else {
                    this.disableType = 'Forensic Watermarking';
                    this.video.forensic_watermarking = true;
                    this.showDisableWatermarkingPopup = true;
                }
            },
            appendHtml() {
                this.video.video_chapters.push({
                    time: '00:00:00',
                    title: null,
                    error: false,
                    titleError: false
                })
            },
            removeHtml(index) {

                // console.log(index, 'removeHtml');

                var temp = [];

                this.video.video_chapters.forEach((value, index1) => {
                    if (index1 !== index) {
                        temp[temp.length] = value;
                    }
                });

                this.video.video_chapters = temp;
                // console.log(this.items, 'removeHtml');

            },
            validateTime(index, type) {

                if (type == "time") {

                    // var expression = /^([0-9]{2})\:([0-9]{2})$/;
                    var expression = /^(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)$/;
                    var regex = new RegExp(expression);
                    var time = this.video.video_chapters[index].time;
                    if (time !== '' && time.match(regex)) {

                        this.video.video_chapters[index].error = false;

                    } else {
                        this.video.video_chapters[index].error = true;
                        return false;
                    }

                } else {

                    var title = this.video.video_chapters[index].title;

                    if (title !== '') {
                        this.video.video_chapters[index].titleError = false;
                    } else {
                        this.video.video_chapters[index].titleError = true;
                        return false;
                    }

                }

            },
            handleCommand(command) {
                const blockedModules = this.$props.blockedModules;
                if (blockedModules.includes("subtitles")) {
                    return false;
                }
                if (command === "uploadSubtitlesFile") {
                    this.uploadSubtitlesFile = true;
                }
                if (command === "generateSubtitlesFile") {
                    this.generateSubtitlesFile = true;
                }
                if (command === "uploadTranslatedSubtitlesFile") {
                    this.uploadTranslatedSubtitlesFile = true;
                }
            },
            handleLanguageCommand(command) {

                this.subtitlesTranslation = true;
                this.selLanguage = command;

            },
            onChange() {
                this.$emit("input", this.selLanguage);
            },
            // checkMove: function(e) {
            //     window.console.log("Future index: " + e.draggedContext.futureIndex);
            // }
            showUpgradeOptions() {
                this.$router.push({name: 'ChangeSubscriptionPlan', params: {'section': 'billing'}});
            },
        },

        beforeDestroy() {
            window.vEvent.stop("update-domain-data", this.updateDomainInfo);
        },
        mounted() {
            console.log('[[===]]',this.video.player_options)
            this.funnel_time = AppHelper.convertSecondsToTime(this.video.player_options.video_funnel_time=='00:00:00'?0:this.video.player_options.video_funnel_time)
            window.vEvent.listen("update-domain-data", this.updateDomainInfo);
            this.$store.dispatch('getSubscriptionData');
        },
        computed: {
            subscriptionData() {
                return this.$store.state.subscription;
            },
            draggingInfo() {
                return this.dragging ? "under drag" : "";
            },
            languages() {

                let languages = this.$store.state.languages;

                return languages;
            },
            valueType() {
                return "code";
            },

        }

    };
</script>

